import { Console } from "console";
import {
    MainFilter,
  } from "../../../../../redux/slices/eventSlice";
  
function removeItemFilter(
    item:string, boxForFilter:MainFilter
  ): MainFilter {

    const { type, cameras__in, status, materials__in } = boxForFilter;
    let newBox = {...boxForFilter}
    type.map((obj)=>{

        if (obj.caption == item){
            const newTypes = type.filter(typeOld => typeOld.caption != item)
            newBox.type = newTypes

        }
    })
    cameras__in.map((obj)=>{
        if (obj.caption == item){
            const newCameras = cameras__in.filter(typeOld => typeOld.caption != item)
            newBox.cameras__in = newCameras
        }
    })

    status.map((obj)=>{
        if (obj.caption == item){
            const newStatus = status.filter(typeOld => typeOld.name != item)
            newBox.status = newStatus
        }
    })
    materials__in.map((obj)=>{
        if (obj.caption == item){
            const newMaterials = materials__in.filter(typeOld => typeOld.name != item)
                newBox.materials__in= newMaterials
            
        }
    })
    return newBox
}  

export default  removeItemFilter;
  