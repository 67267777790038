import React from "react";
import ReactDOM from "react-dom";
import { Pie } from "@ant-design/plots";
import { useGetGroupByCamerasQuery } from "../../../services/statisticApi";
import { useAppSelector } from "../../../hooks/hooks";

interface DataItemDisplayed {
  type: string;
  procent: number;
  count: number;
}
const StatisticCameras: React.FC = () => {
  const { timeRange, count } = useAppSelector((state) => state.statistic);
  const { data: DataByDefect } = useGetGroupByCamerasQuery({
    date_lte: timeRange?.date_end,
    date_gte: timeRange?.date_start,
  });
  // const [config, setConfig] = React.useState<any>(null);
  const [dataDisplayed, setDataDisplayed] = React.useState<DataItemDisplayed[]>(
    []
  );
  React.useEffect(() => {
    if (DataByDefect && count) {
      const data = DataByDefect.items.map((item) => ({
        type: item._id,
        procent: Math.round((item.count / count) * 100),
        count: item.count,
      }));
      setDataDisplayed(data);
    }
  }, [DataByDefect, count]);

  const config = {
    data: [...dataDisplayed],
    appendPadding: 10,
    angleField: "procent",
    colorField: "type",
    paddingRight: 69,
    radius: 0.8,
    innerRadius: 0.5,
    label: {
      text: "count",
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 0,
      },
    },
    annotations: [
      {
        type: "text",
        style: {
          text: "Камеры",
          x: "50%",
          y: "50%",
          textAlign: "center",
          fontSize: 30,
          fontStyle: "bold",
        },
      },
    ],
  };

  return <Pie className="basis-10/12" forceUpdate={true} {...config} />;
};

export default StatisticCameras;
