import { start } from "repl";
import { Event } from "../../../../../types/event";

interface Props {
  events: Event[] | undefined;
}

interface HalfDayPeriod { 
  start: Date
  end: Date
}

export interface TimeLineEvent {
  lineInClock: number;
  event: Event;
  color: "black";
}

function getStartAndEndOfWeek(): { start: Date; end: Date } {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999);
  return { start: today, end: endOfDay };
}

export function getEventsXAxisCircle({ events }: Props): TimeLineEvent[] {
  if (!events || typeof events[0] === 'undefined') {
    return [];
  }

  // const { start, end } = getStartAndEndOfWeek();
  const startDate = new Date(events[0].created)
  startDate.setHours(startDate.getHours() < 12 ? 0 : 12)
  startDate.setMinutes(0)
  startDate.setSeconds(0)
  startDate.setMilliseconds(0)
  
  const offsetCoef = 360 / 43199999; // 12 * 60* 60 * 1000 - 1
  
  return events.map((event: Event) => ({
    lineInClock:
      (((new Date(event.created).getTime() - startDate.getTime()) * offsetCoef +
        90) *
        Math.PI) /
      180,
    event: event,
    color: "black",
  }));
}

export function getPeriodHalfDay(date: Date):HalfDayPeriod{
  const periodStart = date.getHours() < 12 ?
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0,0,0):
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12,0,0)
  const periodEnd = date.getHours() < 12 ?
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11,59,59,999):
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23,59,59,999)
  periodStart.setHours(periodStart.getHours() + 3)
  periodEnd.setHours(periodEnd.getHours() + 3)
  return {start: periodStart,end: periodEnd}
}
