import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import {
  ArchiveButtonActive,
  BurgerFrameActive,
  ButtonsImageState,
  setArchiveFrameActive,
  setBurgerState,
  setButtonsImageState,
} from "../../../../redux/slices/eventSlice";
import { RootState } from "../../../../redux/store";
import { useGetOneEventsQuery } from "../../../../services/eventApi";
import Button from "../../../Button/Button";
import {
  DislikeIcon,
  FilterIcon,
  InfoIcon,
  LikeIcon,
  ListIcon,
  MaskIcon,
  SaveImageIcon,
} from "../../../Burger/BurgerIcons/BurgerIcons";
import { saveAs } from 'file-saver'
import { StatusButtons } from "./StatusButtons";
import getBackend from "../../../../services/utils/getBackend";

export const ClockButtons = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { archiveFrameActive } = useAppSelector(
    (state) => state.events.archiveButtonActive
  );
  const { activeEvent } = useAppSelector((state) => state.events);

  const buttonsImageState = useAppSelector(
    (state: RootState) => state.events.archiveButtonActive.buttonsImageState
  );
  const [imageUrl, setImageUrl] = React.useState<string>('')

  const handleDependItemClick = (
    stateItem: ArchiveButtonActive["archiveFrameActive"]
  ) => {
    if (stateItem == archiveFrameActive) {
      dispatch(setArchiveFrameActive("clockFrame"));
    } else dispatch(setArchiveFrameActive(stateItem));
  };
  React.useEffect(()=>{
    if (activeEvent?.coco?.images?.[0].file_name){
      setImageUrl(getBackend() + '/static/img/' + activeEvent?.coco?.images?.[0].file_name)
    }
  },[activeEvent])
  const downloadImage = () => {
    saveAs(imageUrl, 'image.png') // Put your image URL here.
  }

  return (
    <div className="flex flex-col w-[500px] items-center justify-center gap-[10px]">
      <div className="flex justify-center gap-[40px]">
        <div className="flex border grow border-solid border-blueblue rounded-[10px]">
          <MaskIcon
            active={buttonsImageState.mask}
            onClick={() => {
              dispatch(
                setButtonsImageState({
                  ...buttonsImageState,
                  mask: !buttonsImageState.mask,
                })
              );
            }}
          />
          <SaveImageIcon
            url={imageUrl}
            active={buttonsImageState.saveImage}
            onClick={() => {
                downloadImage()
            }}
          />
          <ListIcon
            active={archiveFrameActive == "listEventsFrame"}
            onClick={() => handleDependItemClick("listEventsFrame")}
          />
        </div>
        {activeEvent && <StatusButtons id={activeEvent._id} />}
      </div>
      <div className="flex justify-between gap-[10px]"></div>
    </div>
  );
};
