import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DateRange, Event } from "../types/event";
import getBackend from "./utils/getBackend";

export interface EventProps {
  id: string;
  update: EventImageUpdate;
}

export interface MainFilterQuery {
  type?: string[];
  status?: string[];
  materials__in?: string[];
  cameras__in?: string[];
  date_gte?: string;
  date_lte?: string;
}

export interface EventImageUpdate {
  view?: boolean;
  type?: string;
  status?: string;
  updaters?: number[];
}

export interface TimeShiftsResponse {
  current: DateRange;
  previous: DateRange;
}

export const eventsApi = createApi({
  reducerPath: "EventsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getBackend() + '/api',
  }),
  tagTypes: ["Events"],
  endpoints: (builder) => ({
    getEvents: builder.query<Event[], MainFilterQuery>({
      query: (params) => ({
        url: "/event-images",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        params: {
          ...params,
        },
      //pollingInterval: 3000,
      }),
      keepUnusedDataFor: 0, 

      // Повторный запрос при монтировании компонента
      // Отключаем кеширование
    }),
    getOneEvents: builder.query<Event, string>({
      query: (id: string) => ({
        url: `event-images/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      keepUnusedDataFor: 0, // Отключаем кеширование
    }),
    getLastEvent: builder.query<Event, void>({
      query: () => ({
        url: `last-event/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      keepUnusedDataFor: 0, // Отключаем кеширование
    }),
    updateEventImage: builder.mutation<Event, EventProps>({
      query: ({ id, update }) => ({
        url: `/event-images/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        method: "PATCH",
        body: update,
      }),
    }),
    getShiftsRange: builder.query<TimeShiftsResponse, void>({
      query: () => ({
        url: "shifts_range/",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      keepUnusedDataFor: 0, // Отключаем кеширование
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetOneEventsQuery,
  useUpdateEventImageMutation,
  useGetShiftsRangeQuery,
  useGetLastEventQuery
} = eventsApi;
