import DatePicker from './DatePicker/DatePicker'
import LocalTimePicker from './LocalTimePicker/LocalTImePicker'

const DateTimePicker = (): JSX.Element => {
  return (
    <div className='flex flex-col w-[600px] bg-[#F5F6F7]'>
      <DatePicker />
      <LocalTimePicker />
    </div>
  )
}
export default DateTimePicker
