import EventInfo from "./EventInfo/EventInfo";
import EventImage from "./EventImage/EventImage";
import Timeline from "./Timeline/Timeline";
import FastFilter from "./Timeline/FastFilter/FastFilter";
import { useGetEventsQuery } from "../../services/eventApi";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveEvent, setActiveIndex } from "../../redux/slices/eventSlice";
import { RootState } from "../../redux/store";
import TimelineMain from "./Timeline/Timeline";
import { useAppSelector } from "../../hooks/hooks";
import getQueryString from "../../services/utils/getQueryString";
import { MainFilterQuery } from "../../services/eventApi";
import createQueryFilter from "../../services/utils/getQueryString";

export const EventView = (): JSX.Element => {
  const { mainFilter, quickFilter } = useAppSelector(
    (state: any) => state.events
  );

  const [mainFilterQuery, setMainFilterQuery] = React.useState<MainFilterQuery>(
    createQueryFilter(mainFilter)
  );

  const filteredEvents = useGetEventsQuery(mainFilterQuery);
  const eventsLength = filteredEvents.data ? filteredEvents.data.length : -1;
  const dispatch = useDispatch();
  const { activeEvent, activeIndex } = useSelector(
    (state: RootState) => state.events
  );

  React.useEffect(() => {
    setMainFilterQuery(createQueryFilter(mainFilter));
  }, [mainFilter, quickFilter]);

  React.useEffect(() => {
    if (filteredEvents.data && activeIndex === -1) {
      dispatch(setActiveEvent(filteredEvents.data[eventsLength - 1]));
      // dispatch(setActiveIndex(eventsLength - 1));
    }
    if (activeIndex >= 0 && filteredEvents.data) {
      dispatch(setActiveEvent(filteredEvents.data[activeIndex]));
    }
  }, [filteredEvents, activeIndex]);

  return <>{activeEvent && <EventImage id={activeEvent._id} />}</>;
};

export default EventView;
