import streamImg from "./assets/img/solar_stream-outline.svg";
import frameImg from "./assets/img/Frame_182.svg";
import fileInfoImg from "./assets/img/ri_file-info-fill.svg";
import solarGraphUpBrokenImg from "./assets/img/solar_graph-up-broken.svg";
import mdiFilterOutlineImg from "./assets/img/mdi_filter-outline.svg";
import feMaskImg from "./assets/img/fe_mask.svg";
import faBookImg from "./assets/img/fa_book.svg";
import homePage from "./assets/img/home-house-svgrepo-com.svg";

import solarGalleryDownloadBrokenImg from "./assets/img/solar_gallery-download-broken.svg";

import BurgerItem from "./BurgerItem";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { RootState } from "../../redux/store";
import {
  setBurgerState,
  BurgerFrameActive,
} from "../../redux/slices/eventSlice";

import {
  FrameIcon,
  KnowledgeIcon,
  HomeIcon,
  StatisticIcon,
} from "./BurgerIcons/BurgerIcons";
import { useLocation, useNavigate } from "react-router-dom";

export const Burger = (): JSX.Element => {
  const navigate = useNavigate();
  const locate = useLocation();
  const falseDependItem: BurgerFrameActive = {
    statistic: false,
    imageViewer: false,
    baseKnowledge: false,
  };
  const burgerState = useAppSelector(
    (state: RootState) => state.events.burgerFrameActive
  );
  const dispatch = useAppDispatch();
  const handleDependItemClick = (stateItem: keyof BurgerFrameActive) => {
    const newDependentState = {
      ...falseDependItem,
      [stateItem]: !burgerState[stateItem],
    };
    dispatch(setBurgerState(newDependentState));
  };

  return (
    <div className="flex flex-col gap-10 z-10">
      <StatisticIcon
        active={locate.pathname === "/statistic" || locate.pathname === "/"}
        onClick={() => {
          handleDependItemClick("statistic");
          navigate("/statistic");
        }}
      />
      <FrameIcon
        active={locate.pathname === "/archive"}
        onClick={() => {
          handleDependItemClick("imageViewer");
          navigate("/archive");
        }}
      />
      <KnowledgeIcon
        active={burgerState.baseKnowledge}
        onClick={() => handleDependItemClick("baseKnowledge")}
      />
    </div>
  );
};
