import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useGetCountItemQuery } from "../../../../services/statisticApi";
import TimeSwitcher from "../../TimeSwitcher";
import { time } from "console";
import { setCount, TimeRange } from "../../../../redux/slices/statisticSlice";
import { useGetShiftsRangeQuery } from "../../../../services/eventApi";
import { set } from "date-fns";

const HeaderMainInfo = () => {
  const dispatch = useAppDispatch();
  const { timeRange, period } = useAppSelector((state) => state.statistic);
  const { data: shifts } = useGetShiftsRangeQuery();
  const [prevTimeRange, setPrevTimeRange] = React.useState<any>({});
  const [previousCount, setPreviousCount] = React.useState(1);
  const { data: currentCount } = useGetCountItemQuery({
    date_lte: timeRange?.date_end,
    date_gte: timeRange?.date_start,
  });
  if (prevTimeRange.date_end) {
    const { data: prevCount } = useGetCountItemQuery({
      date_lte: prevTimeRange.date_end,
      date_gte: prevTimeRange.date_start,
    });
    if (prevCount?.count) {
      setPreviousCount(prevCount.count);
    }
  }

  if (currentCount) {
    dispatch(setCount(currentCount.count));
  }
  // rewrite
  // React.useEffect(() => {
  //   if (timeRange?.date_start && timeRange?.date_end) {
  //     let previousTimeStart: Date | undefined;
  //     let previousTimeEnd: Date | undefined;
  //     if (period === "day") {
  //       previousTimeStart = new Date(timeRange.date_start);
  //       previousTimeStart.setDate(previousTimeStart.getDate() - 1);
  //       previousTimeEnd = new Date(timeRange.date_end);
  //       previousTimeEnd.setDate(previousTimeEnd.getDate() - 1);
  //     }
  //     if (period === "hour") {
  //       previousTimeStart = new Date(timeRange.date_start);
  //       previousTimeStart.setHours(previousTimeStart.getHours() - 1);
  //       previousTimeEnd = new Date(timeRange.date_end);
  //       previousTimeEnd.setHours(previousTimeEnd.getHours() - 1);
  //     }
  //     if (period === "shift2") {
  //       previousTimeStart = new Date(timeRange.date_start);
  //       previousTimeStart.setDate(previousTimeStart.getDate() - 1);
  //       previousTimeEnd = new Date(timeRange.date_end);
  //       previousTimeEnd.setDate(previousTimeEnd.getDate() - 1);
  //     }
  //     if (period === "shift1" && shifts && shifts.previous) {
  //       previousTimeStart = new Date(shifts.previous.start);
  //       previousTimeEnd = new Date(shifts.current.end);
  //     }

  //     const date_end = previousTimeEnd ? previousTimeEnd.toISOString() : "";
  //     const date_start = previousTimeStart
  //       ? previousTimeStart.toISOString()
  //       : "";
  //     setPrevTimeRange({ date_start, date_end });
  //   }
  // }, [period]);
  // console.log("previousCount", previousCount);
  // if (timeRange?.date_start || timeRange?.date_start) {
  // }
  return (
    <div className="flex justify-between">
      <p className="font-gilroy-bold text-[30px]">Общее количество дефектов</p>
      <div>
        <p className="font-gilroy-bold text-[30px]">
          {currentCount?.count ? currentCount.count : 0}
        </p>

        <p className="font-gilroy text-[12px]">
          на 3 больше чем за предыдущие сутки
        </p>
      </div>
      <TimeSwitcher />
    </div>
  );
};

export default HeaderMainInfo;
