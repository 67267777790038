import PropTypes from "prop-types";

interface Props {
  imgUrl: string;
  onClick: () => void;
}

export const NavigationArrowForward = (props: Props): JSX.Element => {
  return (
    <button onClick={props.onClick} className="flex h-full z-10">
      <img className="my-auto" src={props.imgUrl} />
    </button>
  );
};

NavigationArrowForward.propTypes = {
  color: PropTypes.string,
};
