import { AdditionalBlockMain } from "../components/AdditionalBlock/AdditionalBlockMain/AdditionalBlockMain";
import EventsView from "../components/EventsView/EventView";

const Archive = () => {

  return (
    <div className="flex h-[calc(100vh-130px)] w-full gap-2">
      <AdditionalBlockMain />
      <div className="flex-1 justify-center items-center bg-black rounded-[10px]">
        <EventsView />
      </div>
    </div>
  );
};

export default Archive;
