/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  type: "color-border" | "color-center" | "center-color";
  border: "two" | "three" | "four";
  style: "dark" | "light";
  className: any;
}

export const Status = ({ type, border, style, className }: Props): JSX.Element => {
  return (
    <div
      className={`shadow-shadows-gray-blue-30-3-10b rounded-[100px] relative ${
        border === "two"
          ? "border-2 border-solid"
          : border === "three" || (border === "four" && type === "color-center")
          ? "border-[3px] border-solid"
          : type === "center-color"
          ? "border-4 border-solid"
          : ""
      } ${
        type === "color-center" && style === "light"
          ? "border-whitewhite"
          : type === "color-center" && style === "dark"
          ? "border-gray-bluegrey-blue-25"
          : "border-blueblue"
      } ${
        border === "two"
          ? "w-[12px]"
          : border === "three" || (border === "four" && type === "color-center")
          ? "w-[13px]"
          : type === "center-color"
          ? "w-[14px]"
          : ""
      } ${
        border === "two"
          ? "h-[12px]"
          : border === "three" || (border === "four" && type === "color-center")
          ? "h-[13px]"
          : type === "center-color"
          ? "h-[14px]"
          : ""
      } ${
        type === "color-center"
          ? "bg-blueblue"
          : style === "dark" && ["center-color", "color-border"].includes(type)
          ? "bg-gray-bluegrey-blue-25"
          : "bg-whitewhite"
      } ${className}`}
    />
  );
};

Status.propTypes = {
  type: PropTypes.oneOf(["color-border", "color-center", "center-color"]),
  border: PropTypes.oneOf(["two", "three", "four"]),
  style: PropTypes.oneOf(["dark", "light"]),
};
