import React from "react";
import Button from "../../../Button/Button";
import { set } from "date-fns";
import { useGetShiftsRangeQuery } from "../../../../services/otherApi";
import { current } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  setMainFilter, setAutoScroll
} from "../../../../redux/slices/eventSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { NavigationArrowForward } from "./NavigationArrowForward/NavigationArrowForward";
interface ButtonState {
  shift1: boolean;
  shift2: boolean;
  hour: boolean;
}

import ArrowLeft from "./NavigationArrowForward/assets/img/arrow_forward2.svg";
import ArrowRight from "./NavigationArrowForward/assets/img/arrow_forward.svg";
import { getPeriodHalfDay } from "./utils/utils";

// duplikate
export interface Shift {
  name: string;
  start: string;
  end: string;
}

function formatDate(date: Date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
  const year = date.getFullYear();
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  return `${day}/${month}/${year} ${ampm}`;
}
const FastFilter = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { mainFilter, autoScroll } = useAppSelector((state) => state.events);
  // const initialButtonsState: ButtonState = {
  //   shift1: false,
  //   shift2: false,
  //   hour: false,
  // };
  // const [buttonsState, setBattonsState] =
  //   React.useState<ButtonState>(initialButtonsState);

  // const { data: shiftsRange } = useGetShiftsRangeQuery();
  // if (!shiftsRange){
  //   return <></>
  // }
  
  const [date, setDate] = React.useState(new Date());
  
  const [currentDateTime, setCurrentDate] = React.useState(new Date());
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (autoScroll){
        setCurrentDate(new Date())
      };
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);


  React.useEffect(()=>{
    const period = getPeriodHalfDay(new Date())
    // dispatch(setMainFilter({...mainFilter, date_gte: period.start.toISOString(), date_lte: period.end.toISOString()}))
    if (JSON.stringify(getPeriodHalfDay(new Date())) === JSON.stringify(getPeriodHalfDay(date))){
      dispatch(setAutoScroll(true))
    }
  },[date])

  React.useEffect(()=>{
    if (autoScroll){
      setDate(new Date())
    }

  },[autoScroll])
  console.log(mainFilter)
 
  return (
    <div className="flex z-100 justify-center items-center">
       <NavigationArrowForward imgUrl={ArrowLeft} onClick={()=>{
        dispatch(setAutoScroll(false))
        const newDate = new Date(date)
        const dateFrom  = new Date(newDate.getTime() - (12 * 60 * 60 * 1000));
        const period = getPeriodHalfDay(dateFrom)

        console.log('newDate', newDate)
        setDate(dateFrom)
        dispatch(setMainFilter({...mainFilter, date_gte: period.start.toISOString(), date_lte: period.end.toISOString()}))
        }} />
      <div className="font-gilroy rounded-[10px] text-[16px] leading-[20px]">
          Временной промежуток: <p>{ autoScroll? formatDate(currentDateTime):formatDate(date)}</p>
</div>

{JSON.stringify(getPeriodHalfDay(new Date())) != JSON.stringify(getPeriodHalfDay(date)) ?



<NavigationArrowForward
            imgUrl={ArrowRight}
            onClick={()=>{
              dispatch(setAutoScroll(false))
              const newDate = new Date(date)
              const dateFrom  = new Date(newDate.getTime() + (12 * 60 * 60 * 1000));
              const period = getPeriodHalfDay(dateFrom)

              console.log('newDate', newDate)
              setDate(dateFrom)
              dispatch(setMainFilter({...mainFilter, date_gte: period.start.toISOString(), date_lte: period.end.toISOString()}))
            }}/>
          :<div className="min-w-[60px]"></div>}
        {/* <Button
          addClass="shadow z-10"
          active={buttonsState.shift1}
          widthMax={100}
          heigthMax={60}
          name={"1 смена"}
          onClickButton={() => {
            setBattonsState((prev) => ({
              shift2: false,
              hour: false,
              shift1: !prev.shift1,
            }));
            if (shiftsRange) {
              dispatch(
                setMainFilter({...mainFilter,
                  date_gte: shiftsRange.current.start,
                  date_lte: shiftsRange.current.end,
                })
              );
            }
          }}
        /> */}

        {/* <Button
          addClass="z-10 shadow"
          name="2 смены"
          active={buttonsState.shift2}
          onClickButton={() => {
            setBattonsState((prev) => ({
              shift1: false,
              hour: false,
              shift2: !prev.shift2,
            }));
            if (shiftsRange) {
              dispatch(
                setMainFilter({
                  ...mainFilter,
                  date_gte: shiftsRange.previous.start,
                  date_lte: shiftsRange.current.end,
                })
              );
            }
          }}
          widthMax={100}
          heigthMax={60}
        />
        <Button
          addClass="shadow"
          name="1 час"
          active={buttonsState.hour}
          onClickButton={() => {
            setBattonsState((prev) => ({
              shift1: false,
              hour: !prev.hour,
              shift2: false,
            }));
            dispatch(
              setMainFilter({
                ...mainFilter,
                date_gte: new Date(
                  new Date().setHours(new Date().getHours() + 2)
                ).toISOString(),
                date_lte: new Date(
                  new Date().setHours(new Date().getHours() + 3)
                ).toISOString(),
              })
            );
          }}
          widthMax={100}
          heigthMax={60}
        /> */}

    </div>
  );
};

export default FastFilter;
