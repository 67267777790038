import { Checkbox } from "antd";
import { useGetEventTypeQuery } from "../../../../services/otherApi";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { EventType } from "../../../../types/event";
import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
import { setTypeFilter } from "../../../../redux/slices/eventSlice";

const TypeDefectFilter = (): JSX.Element => {
  const { data: types } = useGetEventTypeQuery();
  const dispatch = useAppDispatch();
  const typesFilter = useAppSelector(
    (state: RootState) => state.events.boxForFilter.type
  );

  const onChange = (e: CheckboxChangeEvent, typeFilter: EventType) => {
    if (e.target.checked) {
      dispatch(setTypeFilter([...typesFilter, typeFilter]));
    } else {
      dispatch(
        setTypeFilter([...typesFilter.filter((item) => item !== typeFilter)])
      );
    }
  };
  return types ? (
    <div className="flex flex-wrap gap-4 bg-[#F5F6F7] justify-start p-5">
      {types.map((type) => {
        return (
          <div className="">
            <Checkbox
              className="flex shadow justify-start items-center w-[270px] h-[60px] bg-white rounded-[5px] font-gilroy-bold p-3 gap-1"
              onChange={(e) => {
                onChange(e, type);
              }}
              checked={typesFilter.includes(type)}
            >
              {type.caption}{" "}
            </Checkbox>
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};
export default TypeDefectFilter;
