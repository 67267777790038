import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { RootState } from "../../../redux/store";
import { AdditionalBlockHeader } from "./AdditionalBlockHeader";
import AdditionalFilterBlock from "../AdditionalFilterBlock/AdditionalFilterBlock";
import TableEvents from "../AdditionalArchiveBlock/TableEvents/TableEvents";
import { ClockBlock } from "../AdditionalArchiveBlock/СlockTimeLine/ClockBlock";
import { ClockButtons } from "../AdditionalArchiveBlock/СlockTimeLine/ClockButtons";
import { setAutoScroll } from "../../../redux/slices/eventSlice";
import { Switch } from "antd";

interface Props {
  children: any;
}

export const AdditionalBlockMain = () => {
  const { archiveFrameActive, filterFrameActive } = useAppSelector(
    (state: RootState) => state.events.archiveButtonActive
  );
  const { autoScroll } = useAppSelector(
    (state: RootState) => state.events
  );
  const dispatch = useAppDispatch();
  const [scrollSize, setScrollSize] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      setScrollSize(ref.current.offsetHeight - 100);
    }
  }, [ref]);
  const onChange = (checked: boolean) => {
    dispatch(setAutoScroll(checked));
  };

  return (
    <div className="flex flex-col gap-1 bg-whitewhite rounded-[10px] justify-start items-center">
      <AdditionalBlockHeader />
      <div className="flex h-5"><p>Автообновление</p>
      <Switch className='ml-5' checked={autoScroll} defaultChecked onChange={onChange}/></div>
      <div
        className="flex grow w-[600px] items-center"
        ref={ref}
        style={{ overflow: "auto" }}
      >
        {!filterFrameActive && archiveFrameActive === "clockFrame" && (
          <ClockBlock />
        )}
        {filterFrameActive && <AdditionalFilterBlock size={scrollSize} />}

        {!filterFrameActive && archiveFrameActive === "listEventsFrame" && (
          <TableEvents size={scrollSize} />
        )}
      </div>
      <ClockButtons />
    </div>
  );
};


