import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Event } from "../types/event";
import getBackend from "./utils/getBackend";

export interface DateRangeFilter { // remove exist DatTimeFilter
  date_gte?: string;
  date_lte?: string;
}

export interface GroupByIntervalParams {
  interval: "hour" | "minute";
  params?: DateRangeFilter;
}

export interface GroupItem {
  _id: string;
  count: number;
}

export interface GropByCamerasAndDefects {
  count: number;
  items: GroupItem[];
}

export interface GroupByIntervalItem {
  _id: {
    day?: number;
    hour: number;
    minute?: number;
  };
  count: number;
}
export interface GroupByInterval {
  count: number;
  items: GroupByIntervalItem[];
}

export interface CountItems {
  count: number;
}

export interface DateTimeFilter {
  start?: string;
  end?: string;
}


export interface GeoStatisticsItem{
  plan: number
  fact: number
  time: string
}
interface GeoStatisticsLine {

 width: [GeoStatisticsItem]
 length: [GeoStatisticsItem]
}

export interface GeoStatistics { 
  line_1: GeoStatisticsLine
  line_2: GeoStatisticsLine
}

export const statisticApi = createApi({
  reducerPath: "StatisticApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getBackend() + '/api',
  }),
  tagTypes: ["Statistic"],
  endpoints: (builder) => ({
    getGroupByDefect: builder.query<GropByCamerasAndDefects, DateRangeFilter>({
      query: (params) => ({
        url: "/statistics/group_by_defects/",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        params: {
          ...params,
        },
      }),
      keepUnusedDataFor: 0, // Отключаем кеширование
    }),
    getGroupByCameras: builder.query<GropByCamerasAndDefects, DateRangeFilter>({
      query: (params) => ({
        url: "/statistics/group_by_cameras/",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        params: {
          ...params,
        },
      }),
      keepUnusedDataFor: 0, // Отключаем кеширование
    }),
    getCountItem: builder.query<CountItems, DateRangeFilter>({
      query: (params) => ({
        url: "/statistics/events_count/",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        params: {
          ...params,
        },
      }),
      keepUnusedDataFor: 0, // Отключаем кеширование
    }),
    getGropByInterval: builder.query<GroupByInterval, GroupByIntervalParams>({
      query: (query) => ({
        url: `/statistics/events_count/${query.interval}/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        params: {
          ...query.params,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getStatusSystem: builder.query<string, void>({
      query: () => ({
        url: '/status_system/',
      }),
      keepUnusedDataFor: 0,
    }),
    getGeoStatistisc: builder.query<GeoStatistics, DateTimeFilter>({
      query: (params) => ({
        url: "/geometry_statistics",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        params: {
          ...params,
        },
      }),
      keepUnusedDataFor: 0, // Отключаем кеширование
    }),
  }),
});

export const {
  useGetGroupByDefectQuery,
  useGetGroupByCamerasQuery,
  useGetGropByIntervalQuery,
  useGetCountItemQuery,
  useGetStatusSystemQuery,
  useGetGeoStatistiscQuery
} = statisticApi;


