import React, { useState } from "react";
import { Button, ConfigProvider, Table } from "antd";
import type { TableColumnsType } from "antd";
import { useGetMaterialQuery } from "../../../../services/otherApi";
import { Material } from "../../../../types/event";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { setMaterialFilter } from "../../../../redux/slices/eventSlice";

interface DataType {
  key: React.Key;
  number: React.Key;
  name: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Номер",
    dataIndex: "number",
    width: "10%",
    className: "text-[16px] text-[#4D5E80] font-gilroy text-center",

    // addProps on search filter
  },
  {
    title: "Материал",
    dataIndex: "name",
    className: "text-[16px] text-[#4D5E80] font-gilroy-bold",
    // addProps on search filter
  },
];

const MaterialsFilter: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const filteredMaterial = useAppSelector(
    (state) => state.events.boxForFilter.materials__in
  );
  const dispatch = useAppDispatch();
  const { data: material } = useGetMaterialQuery();

  const start = () => {
    setLoading(true);
    // ajax request after empty completing for button reload
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const getFilterlItems = (
    allMaterial: Material[],
    selectedKey: React.Key[]
  ) => {
    return allMaterial.filter((item) => selectedKey.includes(item.number));
  };

  React.useEffect(() => {
    if (material) {
      const filterItems = getFilterlItems(material, selectedRowKeys);
      dispatch(setMaterialFilter(filterItems));
    } else if (filteredMaterial) {
      setSelectedRowKeys(filteredMaterial.map((item) => item.number));
    } else {
      dispatch(setMaterialFilter([]));
      setSelectedRowKeys([]);
    }
  }, [selectedRowKeys]);

  const dataNE: DataType[] = [];
  if (material && material?.length > 0) {
    for (let i = 0; i < material?.length; i++) {
      dataNE.push({
        key: material[i].number,
        number: material[i].number,
        name: material[i].name,
      });
    }
  }

  const onSelectChange = (newSelectedRowKeys: Material["number"][]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const data: DataType[] = [];

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "white",
            borderRadius: 0,
            colorTextHeading: "#9295AB",
          },
          components: {
            Table: {
              headerBg: "white",
              colorText: "#9295AB",
              fontFamily: "Gilroy-Bold",
              lineHeight: 1,
              fontWeightStrong: 800,
              // headerBg: '#fafafa',
              /* here is your component tokens */
            },
          },
        }}
      >
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataNE}
          pagination={false}
        />
      </ConfigProvider>
    </div>
  );
};

export default MaterialsFilter;

// const columns: TableColumnsType<DataType> = [
//   {
//     title: 'Name',
//     dataIndex: 'name',
//     key: 'name',
//     width: '30%',
//     ...getColumnSearchProps('name'),
//   },
// ]
