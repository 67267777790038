import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/hooks";
import React from "react";
import { useGetLastEventQuery } from "../../../services/eventApi";
import getBackend from "../../../services/utils/getBackend";

const OpenArchive: React.FC = () => {
  const navigate = useNavigate();
  const {data:lastEvent} = useGetLastEventQuery()
  const [fullUrl, setFullUrl] = React.useState<string>()
  React.useEffect(()=>{
    const url = lastEvent?.coco.images[0].file_name
    setFullUrl(getBackend() +'/static/img/'+ url)
  },[lastEvent])
  return (
    <div className="flex flex-col h-full w-full gap-1 items-start m-2">
      <div className="font-gilroy-bold text-[28px] self-center">Последний дефект</div>
      {/* <div className="flex h-full w-full bg-black"> */}
      {fullUrl && <img src={fullUrl} alt="" className="flex object-contain h-3/4 self-center"/>}
      {/* </div> */}
      <button
        onClick={() => {
          navigate("/archive");
        }}
        className="flex justify-center self-center items-center bg-blue-500 text-white font-gilroy-bold text-[18px] w-2/4 h-12 m-1"
      >
        Перейти в режим просмотра
      </button>
    </div>
  );
};

export default OpenArchive;
