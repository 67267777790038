import React, { useState } from "react";
import { format } from "date-fns";

import {
  CaptionProps,
  ClassNames,
  DateRange,
  DayPicker,
  useNavigation,
} from "react-day-picker";
import styles from "react-day-picker/dist/style.css";
import "react-day-picker/dist/style.css";
import { ru } from "date-fns/locale";
import {
  getStartOfDay,
  getEndOfDay,
} from "../../../../../services/utils/getTimeOfDate";

import {
  setDateFromFilter,
  setDateToFilter,
} from "../../../../../redux/slices/eventSlice";
import { useAppDispatch } from "../../../../../hooks/hooks";


const DatePicker = () => {
  const dispatch = useAppDispatch();
  const [range, setRange] = useState<DateRange | undefined>();

  React.useEffect(() => {

    if (range && range.from && range.to) {
      dispatch(setDateToFilter(getEndOfDay({ date: range.to })));
      dispatch(setDateFromFilter(getStartOfDay({ date: range.from })));
    }
  }, [range]);

  function CustomCaption(props: CaptionProps) {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();
    return (
      <div className="flex justify-between items-center px-5 border  h-[50px] bg-white rounded-t-lg">
        <button
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth)}
        >
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.2925 0.292486C6.10567 0.105233 5.85202 0 5.5875 0C5.32298 0 5.06933 0.105233 4.8825 0.292486L0.2925 4.88249C-0.0975 5.27249 -0.0975 5.90249 0.2925 6.29249L4.8825 10.8825C5.2725 11.2725 5.9025 11.2725 6.2925 10.8825C6.6825 10.4925 6.6825 9.86249 6.2925 9.47249L2.4125 5.58249L6.2925 1.70249C6.6825 1.31249 6.6725 0.672486 6.2925 0.292486Z"
              fill="#C3CAD9"
            />
          </svg>
        </button>
        {format(props.displayMonth, "LLLL yyyy", { locale: ru })}

        <button
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
        >
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.2925 0.293762C-0.0975 0.683762 -0.0975 1.31376 0.2925 1.70376L4.1725 5.58376L0.2925 9.46376C-0.0975 9.85376 -0.0975 10.4838 0.2925 10.8738C0.6825 11.2638 1.3125 11.2638 1.7025 10.8738L6.2925 6.28376C6.6825 5.89376 6.6825 5.26376 6.2925 4.87376L1.7025 0.283762C1.3225 -0.0962378 0.6825 -0.0962378 0.2925 0.293762Z"
              fill="#C3CAD9"
            />
          </svg>
        </button>
      </div>
    );
  }

  const classNames: ClassNames = {
    head: "border w-[80px] h-[50px] bg-white rounded",
    table: "border w-[560px] h-[240px] bg-white",
    // tbody: 'border w-[80px] h-[40px] bg-white',
    day: "border w-[80px] h-[40px] bg-white",
    // caption: 'flex flex-around border  h-[50px] bg-white rounded-t-md',
    cell: "border",
    // day_selected: 'border w-[80px] h-[40px] bg-blue-100 rounded-t-md',
  }; 

  return (
    <div className="flex justify-center font-gilroy-bold text-[14px] text-[#9295AB] ">
      <DayPicker
        components={{
          Caption: CustomCaption,
        }}
        showOutsideDays={true}
        id="test"
        locale={ru}
        mode="range"
        defaultMonth={new Date()}
        selected={range}
        onSelect={setRange}
        classNames={classNames}
      />
    </div>
  );
};

export default DatePicker;
