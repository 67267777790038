import { AdditionalBlockMain } from "../components/AdditionalBlock/AdditionalBlockMain/AdditionalBlockMain";
import EventsView from "../components/EventsView/EventView";
import { MnemonicElem } from "../components/MnemonicSchema/MnemonicElem";

const MnemonicSchema = () => {

  return (
    <div className="flex flex-wrap">
      <MnemonicElem />
      <MnemonicElem />
      <MnemonicElem />
      <MnemonicElem />
    </div>
  );
};

export default MnemonicSchema;
