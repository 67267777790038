import React from "react";

import { Stage, Image, Layer, Circle, Line, Text } from "react-konva";

import useImage from "use-image";



// import logoMain from "./is_logo_kontur.png"
import imageCompas from "./Поворот_стол_Робот-2_Л2_52,53.jpg";


const CompassImage = () => {
  const [image] = useImage(imageCompas);
  return <Image image={image} x={-137} y={-118} width={700} height={700} />;
};
const fiftyItemArray = Array.from({ length: 50 }, (_, index) => index + 1);


export const MnemonicElem: React.FC = () => {
  const [image] = useImage(imageCompas);
  return (
    <div className="flex ">
      <Stage width={400} height={400}>
        <Layer>
          {fiftyItemArray.map((_, idx) => (
              <Line
                key={idx}
                points={[
                  200,
                  200,
                  200 - 190 * Math.cos(360 * idx / 50 - 180),
                  200 - 190 * Math.sin(360 * idx / 50 - 180 ),
                ]}
                // stroke= { _.event._id !== activeEvent?._id ? "#8DA7FF":  "#b81414"}
                stroke = {idx % 4 != 0 ? "green" : "red"}
                strokeWidth={12}
                onClick={() => {console.log(idx, 360 * idx / 50)}}
              />
            ))}
             <Circle
                x={200} 
                y={200} 
                radius={180} 
                fillPatternImage={image}
                fillPatternRepeat="no-repeat"
                fillPatternScale={{ x: 0.55, y: 0.55 }}
                fillPatternOffset={{ x: 460, y: 380 }}
                fillPatternRotation={0}

            />
        </Layer>
      </Stage>
    </div>
  );
};
