import React, { useState } from "react";
import { Button, ConfigProvider, Modal } from "antd";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

interface ModalProps {
  type: "error" | "success";
  title: string;
  describe: string;
  onOk: () => void;
}

const ModalMessage = (Props: ModalProps): JSX.Element => {
  const [modal2Open, setModal2Open] = useState(true);
  const color = Props.type === "error" ? "red" : "green";
  const icon =
    Props.type === "error" ? <ExclamationCircleOutlined /> : <CheckOutlined />;
  const buttonInfo =
    Props.type === "error" ? "Проблема устранена" : "Ок, спасибо";
  const colorButtonText = Props.type ? "text-blue-300" : "text-green-300";

  return (
    <>
      {/* <Button type="primary" onClick={() => setModal2Open(true)}>
        Vertically centered modal dialog
      </Button> */}

      <ConfigProvider
        theme={{
          components: {
            Modal: { contentBg: color, headerBg: color },
          },
          token: {
            padding: 0,
            margin: 0,
          },
        }}
      >
        <Modal
          classNames={{}}
          title={
            <div className="flex justify-center text-[40px] bg-red-650">
              {icon}
            </div>
          }
          centered
          open={modal2Open}
          // onOk={() => {}}
          footer={
            <div className="flex justify-center border-t rounded-b-[6px] py-[10px] bg-white my-[-20px] mx-[-24px]">
              <Button
                className="font-gilroy-bold border-none text-[20px] items-center justify-center"
                onClick={Props.onOk}
              >
                {buttonInfo}
              </Button>
            </div>
          }
        >
          <div className="flex flex-col justify-center">
            <div className="flex text-center justify-center text-[40px] font-gilroy-bold text-white">
              {Props.title}
            </div>
            <div className="flex text-white text-center text-[20px] p-10">
              {Props.describe}
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default ModalMessage;
