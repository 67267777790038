interface FilterItemProps {
  name: string
  number: React.Key
  onClick: () => void
}

const FilterItemView = (props: FilterItemProps) => {
  return (
    <div className='flex items-center m-[5px]'>
      <svg
        className='flex self-center justify-end pl-[1px]'
        width='6'
        height='11'
        viewBox='0 0 5 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M5 10L5 0L0 5L5 10Z'
          fill='#3361FF'
        />
      </svg>

      <div className='flex  justify-center gap-[3px] pt-[3px] px-[5px] text-white h-[30px] bg-[#3361FF] rounded-[5px] '>
        <div>{props.name}</div>

        <button onClick={props.onClick}>
          <svg
            className='pb-[3px]'
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M10.1611 0.304574L5.99463 4.34493L1.82819 0.304574C1.62756 0.109582 1.35519 0 1.07114 0C0.787095 0 0.514718 0.109582 0.314094 0.304574C-0.104698 0.710693 -0.104698 1.36673 0.314094 1.77285L5.24295 6.55255C5.66175 6.95867 6.33826 6.95867 6.75705 6.55255L11.6859 1.77285C12.1047 1.36673 12.1047 0.710693 11.6859 0.304574C11.2671 -0.0911314 10.5799 -0.101545 10.1611 0.304574Z'
              fill='#E62E2E'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M10.1611 11.6954L5.99463 7.65507L1.82819 11.6954C1.62756 11.8904 1.35519 12 1.07114 12C0.787095 12 0.514718 11.8904 0.314094 11.6954C-0.104698 11.2893 -0.104698 10.6333 0.314094 10.2272L5.24295 5.44745C5.66175 5.04133 6.33826 5.04133 6.75705 5.44745L11.6859 10.2272C12.1047 10.6333 12.1047 11.2893 11.6859 11.6954C11.2671 12.0911 10.5799 12.1015 10.1611 11.6954Z'
              fill='#E62E2E'
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default FilterItemView
