import {
  MainFilter,
  QuickFilter,
} from "../../../../../redux/slices/eventSlice";

function createQueryFilter(
  mainFilter: MainFilter
): string[] {
  
  const { type, cameras__in, status, materials__in } = mainFilter;
  let arrayQueryFilter: string[] = [];
  if (mainFilter.date_lte) {
    const DateTimeLte = new Date(mainFilter.date_gte)
    arrayQueryFilter.push(mainFilter.date_gte);
  }

  if (mainFilter.date_gte) {
    const DateTimeGte = new Date(mainFilter.date_lte)
    arrayQueryFilter.push(mainFilter.date_lte);
  }

  arrayQueryFilter = arrayQueryFilter.concat(
    type.map((item) => {
      return item.caption;
    })
  );
  arrayQueryFilter = arrayQueryFilter.concat(
    cameras__in.map((item) => {
      return item.caption;
    })
  );
  arrayQueryFilter = arrayQueryFilter.concat(
    status.map((item) => {
      return item.caption;
    })
  );
  arrayQueryFilter = arrayQueryFilter.concat(
    materials__in.map((item) => {
      return item.caption;
    })
  );
  return arrayQueryFilter;
}

export default createQueryFilter;
