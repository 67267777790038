import React from "react";
import { useGetStatusesQuery } from "../../../../services/otherApi";
import {
  useUpdateEventImageMutation,
  useGetOneEventsQuery,
} from "../../../../services/eventApi";
import Button from "../../../Button/Button";
import { DislikeIcon, LikeIcon } from "../../../Burger/BurgerIcons/BurgerIcons";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { Divider } from "antd";
import { setActiveEvent } from "../../../../redux/slices/eventSlice";
import { set } from "date-fns";

interface EventImageUpdate {
  view?: boolean;
  type?: string;
  status?: string;
  updaters?: number[];
}

interface Status {
  id: string;
  name: string;
  auto: boolean;
  active: boolean;
}

interface Props {
  id: string;
}

export const StatusButtons = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [updateImage] = useUpdateEventImageMutation();
  const updateStatus = (id: string, data: EventImageUpdate) => {
    updateImage({ id: id, update: data });
  };
  const [status, setStatus] = React.useState<string>("Новый");
  const { data } = useGetOneEventsQuery(props.id);
  React.useEffect(() => {
    if (data) {
      setStatus(data.status);
    }
  }, [data]);

  return (
    <div>
      {data && (
        <div className="flex border grow border-solid border-blueblue rounded-[10px]">
          <LikeIcon
            active={status === "Дефект"}
            onClick={() => {
              updateStatus(data._id, { status: "Дефект" });
              setStatus("Дефект");
            }}
          />
          <DislikeIcon
            active={status === "Нет дефект"}
            onClick={() => {
              updateStatus(data._id, { status: "Нет дефект" });
              setStatus("Нет дефект");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default StatusButtons;
