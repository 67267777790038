import React from "react";
import InfoUs from "../components/Auth/InfoUs";
import Login from "../components/Auth/Login/Login";
import Registration from "../components/Auth/Registration/Registration";
import { Header } from "../components/Header";
import ModalMessage from "../components/ModalMessage/ModalMessage";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { setModal } from "../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";

// псделать через чилдрен на регу и логин
const RegistrationPage = () => {
  const modal = useAppSelector((state) => state.users.modal);
  const dispath = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div
      id="layout"
      className="flex bg-[#f1f2f6] h-screen w-screen p-5 justify-center"
    >
      <div className="flex gap-10 w-4/5 h-3/4 self-center">
        <Registration />
        <InfoUs />
      </div>
      {modal && (
        <ModalMessage
          type="success"
          title="Регистрация"
          describe="Вы успешно зарегистрировались. Вы будете перенаправлены на страницу авторизации"
          onOk={() => {
            dispath(setModal(false));
            navigate("/login");
          }}
        />
      )}
    </div>
  );
};

export default RegistrationPage;
