import notifyImg from "./assets/img/icon.svg";
import settingsImg from "./assets/img/carbon-settings.svg";
import exitImg from "./assets/img/group-8923.png";
import iconImg from "./assets/img/radix-icons-avatar.svg";
// import PropTypes from "prop-types";
import React from "react";
import { Status } from "./Status";
import logoMain from "./assets/img/is_logo_main.png";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { setIsAuth } from "../../redux/slices/userSlice";
import CurrentTime from "../StatisticElem/CurrentTime";
interface Props {
  className: any;
}

export const Header = (): JSX.Element => {
  const { blockInfo } = useAppSelector(
    (state: RootState) => state.events
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div className="flex-auto shrink grow  font-gilroy h-[80px] bg-white rounded-[5px] shadow-[0px_8px_28px_#4859660d]">
      <div className="flex items-center justify-between">
        <div className="flex items-start gap-[11px]">
          <img
            src={logoMain}
            alt="logo main"
            className="h-[80px] scale-[2.5]  ml-[90px] -z-100"
          />
          {/* <div className="text-[32px] relative text-[#1f263d] leading-[15px]">
            RIG/
          </div> */}
          <div className="self-center font-gilroy-bold font-bold ml-[50px] text-[32px] text-[#1f263d]">
          Санкт-Петербург / ИСКИН / {blockInfo}
          </div>
        </div>
        <div className="flex  gap-5 text-[18px] text-[#1f263d] leading-[15px]">
          
          <CurrentTime/>
        </div>
        <div className="w-[380px] h-[43px]">
          <div className="inline-flex items-center gap-[20px] relative">
            <div className="relative w-[36px] h-[36px]">
              <img
                className="absolute w-[18px] h-[23px] top-[6px] left-[8px]"
                alt="Icon"
                src={notifyImg}
              />
              <Status
                border="two"
                className="!border-[unset] !h-[10px] !absolute !left-[28px] !bg-[#e62e7b] !w-[10px]"
                style="light"
                type="color-border"
              />
            </div>
            <img
              className="relative w-[36px] h-[36px]"
              alt="Carbon settings"
              src={settingsImg}
            />
            <button
              onClick={() => {
                localStorage.setItem('refreshToken', '')
                localStorage.setItem('accessToken', '')
                dispatch(setIsAuth(false));
                navigate("/login");
                // localStorage.removeItem("refreshToken");
                // localStorage.removeItem("userId");
                // localStorage.removeItem("username");
                // localStorage.removeItem("role");
                // localStorage.removeItem("block");
                // localStorage.removeItem("blockInfo");
                // localStorage.removeItem("city");
                // localStorage.removeItem("company");
              }}
            >
              <img
                className="relative w-[36px] h-[36px]"
                alt="Group"
                src={exitImg}
              />
            </button>

            <div className="inline-flex items-center gap-[24px] relative flex-[0_0_auto]">
              <img
                className="relative w-px h-[10px] object-cover"
                alt="Line"
                src="/img/line-47.svg"
              />
              <div className="inline-flex items-center gap-[10px] relative flex-[0_0_auto]">
                <img
                  className="relative w-[40px] h-[40px]"
                  alt="Radix icons avatar"
                  src={iconImg}
                />
                <div className="inline-flex flex-col items-start justify-center gap-[5px] relative flex-[0_0_auto]">
                  <div className="relative w-fit mt-[-1.00px] [font-family:'Gilroy-Regular',Helvetica] font-normal text-[#252c58] text-[16px] tracking-[0] leading-[normal] whitespace-nowrap">
                    Роль
                  </div>
                  <div className="relative w-fit [font-family:'Gilroy-Bold',Helvetica] font-bold text-[#252c58] text-[16px] tracking-[0] leading-[normal] whitespace-nowrap">
                    Имя Фамилия
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
