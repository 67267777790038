import InfoUs from "../components/Auth/InfoUs";
import Login from "../components/Auth/Login/Login";
import { Header } from "../components/Header";

// псделать через чилдрен на регу и логин
const Auth = () => {
  return (
    <div
      id="layout"
      className="flex bg-[#f1f2f6] h-screen w-screen p-5 justify-center"
    >
      <div className="flex gap-10 w-4/5 h-3/4 self-center">
        <Login />
        <InfoUs />
      </div>
    </div>
  );
};

export default Auth;
