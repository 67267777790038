import { Header } from "../components/Header";
import { Burger } from "../components/Burger";

import {} from "../redux/slices/eventSlice";
import { Outlet } from "react-router-dom";

const PageLayout = () => {
  return (
    <div id="layout" className="bg-[#f1f2f6] h-screen w-screen px-5">
      <div className="flex py-[20px]">
        <Header />
      </div>
      <div className="flex grow shrink justify-start h-max gap-5 ">
        <Burger />
        <Outlet />
      </div>
    </div>
  );
};

export default PageLayout;
