import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { Button, Checkbox, Form, type FormProps, Input } from "antd";
import { useCreateUserMutation, CreateUser } from "../../services/userApi";
import { set } from "date-fns";
import imageUrl from "./photo_2024-03-30 14.48.57.jpeg";
import Logo from "./is_logo_nogradint_2.png";

const InfoUs: React.FC = () => {
  const handleButtonClick = () => {};
  const infoText = "ИСКИН Версия ядра:3.0";
  return (
    <div
      className={`flex flex-col justify-between grow shrink rounded-[10px] p-5 bg-defectoscope`}
    >
      <div className="gap-10">
        <img
          src={Logo}
          alt="Logo"
          className="w-1/4 self-start leading-[-100px] pb-[400px] "
        />
        <p className="font-gilroy-bold text-white text-[45px]">{infoText}</p>
        <p className="font-gilroy-bold text-white text-[20px]">Версия 3.7.6</p>
      </div>

      <button
        onClick={handleButtonClick}
        className="self-center justify-end backdrop-blur-sm w-3/5 h-[80px] font-gilroy-bold text-[20px] rounded-lg shadow-lg"
      >
        Связаться с техподдержкой
      </button>
    </div>
  );
};

export default InfoUs;
