import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  type FormProps,
  Input,
} from "antd";
import { useGetAccessTokenMutation} from "../../../services/userApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { setIsAuth } from "../../../redux/slices/userSlice";
import { RootState } from "../../../redux/store";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Header } from "../../Header";

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
  errorInfo
) => {};

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const is_auth = useAppSelector((state: RootState) => state.users.is_auth);
  const location = useLocation();
  const from = location.state?.from?.pathname || "/archive";
  const [getAccessToken, result] = useGetAccessTokenMutation();
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    if (values.username && values.password) {
      getAccessToken({
        username: values.username,
        password: values.password,
      });
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("accessToken") !== null && is_auth === true) {
      navigate(from);
    }
    if (result.data) {
      const accessToken = result.data.access_token;
      const refreshToken = result.data.refresh_token
      dispatch(setIsAuth(true));
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      navigate(from);
    }
  }, [result.data]);
  return (
    <div className="flex flex-col w-[620px] grow rounded-[10px] bg-white p-5">
      <div className="font-gilroy text-[#9295AB]"> Город, предприятие</div>
      <div className="flex flex-col font-gilroy-bold text-black text-[32px] items-center justify-center py-6">
        Войти в аккаунт
        <div className="w-[450px] px-10">
          <ConfigProvider
            theme={{
              components: {
                Form: {
                  labelFontSize: 24,
                },
              },
              token: {
                // controlOutline: "#9295AB",
              },
            }}
          >
            <Form
              name="basic"
              style={{ maxWidth: 450 }}
              className="font-gilroy"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="on"
              layout="vertical"
            >
              <Form.Item<FieldType>
                label="Логин"
                name="username"
                rules={[{ message: "Please input your username!" }]}
                className="font-gilroy "
              >
                <Input
                  variant="filled"
                  className="font-gilroy font-semibold text-[20px] h-[50px]"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label="Пароль"
                name="password"
                rules={[{ message: "Please input your password!" }]}
              >
                <Input.Password
                  variant="filled"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  className="font-gilroy font-semibold text-[16px] h-[50px]"
                />
              </Form.Item>
              <a
                className="flex justify-center font-gilroy text-center text-[#9295AB] underline"
                href=""
              >
                Не помню пароль
              </a>
              <Form.Item className="flex grow shrink justify-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="flex justify-center px-[100px] font-gilroy-bold text-[20px] h-[50px] mt-3 bg-[blue] items-center"
                >
                  Войти
                </Button>
              </Form.Item>
              <Form.Item className="flex grow shrink justify-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="flex justify-center px-[100px] font-gilroy-bold text-[20px] h-[50px] mt-3 bg-[blue] items-center"
                  onClick={() => navigate("/registration")}
                >
                  Регистрация
                </Button>
              </Form.Item>
            </Form>
          </ConfigProvider>
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Login;
