import { useAppDispatch } from "../../../../hooks/hooks";
import { setBlockInfo } from "../../../../redux/slices/eventSlice";
import { Clock } from "./Clock";

export const ClockBlock = (): JSX.Element => {
  const dispatch = useAppDispatch();
  dispatch(setBlockInfo("Таймлайн"));
  return <Clock />;
};

