import {
  useGetLinesQuery,
  useGetCamerasQuery,
} from "../../../../../services/otherApi";

import React from "react";
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import { RootState } from "../../../../../redux/store";
import { setCamerasFilter } from "../../../../../redux/slices/eventSlice";
import { Camera } from "../../../../../types/event";
import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";

interface Props {
  lineId: string;
}

const CameraLineList = ({ lineId }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const camerasFilter = useAppSelector(
    (state: RootState) => state.events.boxForFilter.cameras__in
  );

  const onChange = (e: CheckboxChangeEvent, camera: Camera) => {
    if (e.target.checked) {
      dispatch(setCamerasFilter([...camerasFilter, camera]));
    } else {
      dispatch(
        setCamerasFilter([...camerasFilter.filter((item) => item !== camera)])
      );
    }
  };
  const { data: cameras } = useGetCamerasQuery({ lines: lineId });
  console.log(cameras)
  return cameras ? (
    <div>
      <div className="flex font-gilroy-bold text-[#194DFF] m-5 justify-between">
        <div className="">{lineId}</div>
        <button
          className="font-gilroy"
          onClick={() => {
            dispatch(
              setCamerasFilter(
                Array.from(new Set([...camerasFilter, ...cameras]))
              )
            );
          }}
        >
          Выбрать все
        </button>
      </div>
      <div className="flex flex-wrap gap-4  justify-start m-5 ">
        {cameras.map((camera) => {
          return (
            <div className="">
              <Checkbox
                className="flex shadow rounded justify-start items-center w-[260px] h-[60px] bg-white font-gilroy-bold p-5"
                onChange={(e) => {
                  onChange(e, camera);
                }}
                checked={camerasFilter.includes(camera)}
              >
                {camera.caption}
              </Checkbox>
            </div>
          );
        })}{" "}
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default CameraLineList;
