import React, { useRef } from "react";
import { Table, Typography, ConfigProvider } from "antd";
import type { TableColumnsType } from "antd";
import Button from "../../../Button/Button";
import infoIcon from "./info.svg";
import {
  MainFilterQuery,
  useGetEventsQuery,
} from "../../../../services/eventApi";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import createQueryFilter from "../../../../services/utils/getQueryString";
import {
  MainFilter,
  setActiveEvent,
  setActiveIndex,
  setBlockInfo,
} from "../../../../redux/slices/eventSlice";
import { InfoOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import InfoIcon from "./info.svg";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { TimeLineEvent } from "../СlockTimeLine/utils/utils";
import { Event } from "../../../../types/event";
import { set } from "date-fns";
interface FixedDataType {
  _id: string;
  id: string;
  name: string;
  description: string;
  shift: string;
  camera: string;
  type: string;
}

interface Props {
  size: number;
}

const a = () => (
  <svg
    width="12"
    height="24"
    viewBox="0 0 12 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.91838 0C10.5264 0 11.3304 1.0944 11.3304 2.3484C11.3304 3.9144 9.93358 5.3628 8.11558 5.3628C6.59278 5.3628 5.70478 4.4628 5.74678 2.9748C5.74678 1.7232 6.80398 0 8.91838 0ZM3.97078 24C2.70118 24 1.77118 23.2176 2.65918 19.7712L4.11598 13.6608C4.36918 12.684 4.41118 12.2916 4.11598 12.2916C3.73558 12.2916 2.08918 12.966 1.11358 13.632L0.47998 12.576C3.56638 9.9528 7.11718 8.4156 8.64118 8.4156C9.90958 8.4156 10.1208 9.9432 9.48718 12.2916L7.81798 18.714C7.52278 19.848 7.64878 20.2392 7.94518 20.2392C8.32558 20.2392 9.57358 19.7688 10.8 18.7908L11.52 19.7676C8.51758 22.824 5.23798 24 3.97078 24Z"
      fill="#9295AB"
    />
  </svg>
);

const HeartIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={a} {...props} />
);

const fixedColumns: TableColumnsType<FixedDataType> = [
  {
    title: "Время",
    dataIndex: "created",
    width: 100,
    className: "",
  },
  {
    title: "Тип дефекта",
    dataIndex: "type",
    className: "text-[16px] text-[#4D5E80] font-gilroy-bold",
    width: 200,
  },
  {
    title: "Смена",
    dataIndex: "shift",
  },
  {
    title: "Камера",
    dataIndex: "camera",
  },
  {
    title: "Инфо",
    dataIndex: "",
    align: "center",
    key: "x",
    render: () => (
      <a className="flex justify-center">
        <HeartIcon />
      </a>
    ),
  },
];

const TableEvents = ({ size }: Props): JSX.Element => {
  const { mainFilter, activeEvent, activeIndex } = useAppSelector(
    (state) => state.events
  );
  const [scrollSize, setScrollSize] = React.useState(0);
  const dispatch = useAppDispatch();
  const ref = React.useRef<HTMLDivElement>(null);
  dispatch(setBlockInfo("Список событий"));
  const [mainFilterQuery, setMainFilterQuery] = React.useState<MainFilterQuery>(
    createQueryFilter(mainFilter)
  );
  const { data: filteredEvents } = useGetEventsQuery(mainFilterQuery);
  React.useEffect(() => {
    setMainFilterQuery(createQueryFilter(mainFilter));
  }, [mainFilter]);

  React.useEffect(() => {
    if (ref.current) {
      setScrollSize(ref.current.offsetHeight);
    }
  }, [ref]);
  const onClickLine = (some: string) => {
    if (filteredEvents) {
      const item: Event | undefined = filteredEvents.find((e) => {
        return e._id === some;
      });

      if (item) {
        const indexItem = filteredEvents.indexOf(item);
        dispatch(setActiveEvent(item));
        dispatch(setActiveIndex(indexItem));
      }
    }
  };


  return (
    <div className="flex grow flex-col self-start  mb-[40px]" ref={ref}>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "white",
            borderRadius: 0,
            colorTextHeading: "#9295AB",
          },
          components: {
            Table: {
              headerBg: "white",
              colorText: "#9295AB",
              fontFamily: "Gilroy-Regular",
              lineHeight: 1,
              fontWeightStrong: 800,
            },
          },
        }}
      >
        <Table
          columns={fixedColumns}
          dataSource={filteredEvents}
          pagination={false}
          scroll={{ y: size }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                onClickLine(record._id);
              },
            };
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default TableEvents;
