import TimeSwitcher from "../../TimeSwitcher";
import CurrentTime from "../../CurrentTime";
import HeaderMainInfo from "./HeaderMainInfo";
import Charts from "./Charts";

const MainInfo: React.FC = () => {
  return (
    <div className="flex w-3/6 h-1/2">
      <div className="flex flex-col grow bg-white rounded-xl shadow-md m-2 p-2 justify-between">
        <HeaderMainInfo />
        <Charts />
      </div>
    </div>
  );
};

export default MainInfo;
