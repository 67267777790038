export const getLocalAccesToken = (): string | null => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    return token;
  }
  return null;
};

export const getLocalRefreshToken = (): string | null => {
  const token = localStorage.getItem("refreshToken");
  if (token) {
    return token;
  }
  return null;
};

