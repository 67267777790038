interface DateProps {
  date?: Date
}

export function getStartOfDay(props: DateProps): string {
  const startOfDay = props.date || new Date()
  const timezoneOffset = startOfDay.getTimezoneOffset();
  startOfDay.setHours(0, 0, 0) 
  startOfDay.setHours(startOfDay.getHours() - timezoneOffset/60)
  return encodeURI(startOfDay.toISOString())
}

export function getEndOfDay(props: DateProps): string {
  const endOfDay = props.date || new Date()
  const timezoneOffset = endOfDay.getTimezoneOffset();
  endOfDay.setHours(23, 59, 59)
  endOfDay.setHours(endOfDay.getHours() - timezoneOffset/60)
  return encodeURI(endOfDay.toISOString())
}