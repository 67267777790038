import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Event } from "../types/event";
import getBackend from "./utils/getBackend";

interface AuthProps {
  username: string;
  password: string;
}

interface CreateProps {
  username: string;
  password: string;
  login: string;
}

interface Token {
  access_token: string;
  refresh_token: string;
  token_type: string;
}

export interface EventImageUpdate {
  view?: boolean;
  type?: string;
  status?: string;
  updaters?: number[];
}

export interface CreateUser {
  email: string;
  login: string;
  password: string;
  last_name: string;
  first_name: string;
  role_id: number;
}

interface ResponseUser {
  id: number;
  email: string;
  login: string;
  is_active: boolean;
  is_admin: boolean;
}

export const authApi = createApi({
  reducerPath: "AuthApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getBackend() + '/api',
  }),
  endpoints: (builder) => ({
    getAccessToken: builder.mutation<Token, AuthProps>({
      query: (AuthProps) => {
        var bodyFormData = new FormData();
        bodyFormData.append("username", AuthProps.username);
        bodyFormData.append("password", AuthProps.password);
        return {
          url: "/token",
          method: "POST",
          body: bodyFormData,
        };
      },
    }),
    updateTokens: builder.mutation<Token, string>({
      query: (token) => {
        return {
          url: `/refresh?refresh_token=${token}`,
          method: "POST",
          body: {},
        };
      },
    }),
    getMe: builder.query<ResponseUser, void>({
      query: () => ({
        url: 'users/me/',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      keepUnusedDataFor: 0, // Отключаем кеширование
    }),
    createUser: builder.mutation<ResponseUser, CreateUser>({
      query: (CreateUser) => {
        return {
          url: "/users",
          method: "POST",
          body: {
            email: CreateUser.email,
            login: CreateUser.login,
            password: CreateUser.password,
            first_name: CreateUser.first_name,
            last_name: CreateUser.last_name,
            role_id: 1,
          },
        };
      },
    }),
  }),
});

export const { useGetAccessTokenMutation,useUpdateTokensMutation, useCreateUserMutation, useGetMeQuery } = authApi;
