import React from "react";
import { MainFilter, setMainFilter, setBoxToMainFilter, setAutoScroll, setIsFilterOn, setArchiveFrameActive } from "../../../../redux/slices/eventSlice";
import {
  getEndOfDay,
  getStartOfDay,
} from "../../../../services/utils/getTimeOfDate";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { getPeriodHalfDay } from "../../AdditionalArchiveBlock/СlockTimeLine/utils/utils";


const clearMainFilter: MainFilter = {
  date_lte: getEndOfDay({}),
  date_gte: getStartOfDay({}),
  type: [],
  status: [],
  materials__in: [],
  cameras__in: [],
};

const ButtonClearFilter = () => {
  const period = getPeriodHalfDay(new Date())
  const clearMainFilter: MainFilter = {
    date_lte: period.end.toISOString(),
    date_gte: period.start.toISOString(),
    type: [],
    status: [],
    materials__in: [],
    cameras__in: [],
  };
  const [activeButton, setActiveButton] = React.useState(false);
  const colorIconButton = activeButton ? "#3361FF" : "#9295AB";
  const colorTextButton = activeButton ? "text-[#3361FF]" : "text-[#9295AB]";

  const dispatch = useAppDispatch();
  const { boxForFilter, autoScroll } = useAppSelector((state) => state.events);

  React.useEffect(() => {
    console.log('equel', JSON.stringify(boxForFilter), JSON.stringify(clearMainFilter))
    if (JSON.stringify(boxForFilter) !== JSON.stringify(clearMainFilter) || !autoScroll) {
      setActiveButton(true);
      dispatch(setIsFilterOn(true))
    } else {
      //setActiveButton(true);
      dispatch(setIsFilterOn(false))
    }
  }, [boxForFilter, autoScroll]);

  const onClickButton = () => {
    // dispatch(setMainFilter(clearMainFilter));
    const period = getPeriodHalfDay(new Date())
    dispatch(setBoxToMainFilter({...clearMainFilter,date_gte: period.start.toISOString(), date_lte: period.end.toISOString()}));
    setActiveButton(false);
    dispatch(setAutoScroll(true))
    dispatch(setArchiveFrameActive("clockFrame"))

  };

  return (
    <button
      onClick={() => {
        onClickButton();
      }}
      disabled={!activeButton}
      className={`flex pt-[3px] font-gilroy-bold text-[14px] justify-center items-center gap-3 w-[196px] h-[38px] bg-[#D5D9DD]/20 rounded-[10px] ${colorTextButton}`}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.7879 16.1927C10.825 16.4665 10.7321 16.7587 10.5184 16.9504C10.4324 17.035 10.3303 17.1022 10.2179 17.148C10.1055 17.1938 9.985 17.2174 9.8633 17.2174C9.7416 17.2174 9.6211 17.1938 9.50868 17.148C9.39627 17.1022 9.29416 17.035 9.2082 16.9504L5.48203 13.2896C5.38068 13.1923 5.30362 13.0732 5.25688 12.9418C5.21013 12.8103 5.19496 12.67 5.21256 12.5319V7.85772L0.761595 2.26152C0.610697 2.07121 0.542609 1.82995 0.572209 1.59046C0.601808 1.35098 0.726689 1.13274 0.919562 0.983435C1.09611 0.855626 1.29125 0.782593 1.49568 0.782593H14.5047C14.7092 0.782593 14.9043 0.855626 15.0809 0.983435C15.2737 1.13274 15.3986 1.35098 15.4282 1.59046C15.4578 1.82995 15.3897 2.07121 15.2388 2.26152L10.7879 7.85772V16.1927ZM3.39129 2.60843L7.07099 7.22781V12.2671L8.92943 14.093V7.21868L12.6091 2.60843H3.39129Z"
          fill={colorIconButton}
        />
      </svg>
      Сбросить фильтр
    </button>
  );
};

export default ButtonClearFilter;
function HalfDayPeriod() {
  throw new Error("Function not implemented.");
}

