import { Table } from "antd";
import CurrentTime from "../CurrentTime";
import StatisticSmallElem from "../StatisticSmallElem";
import { useGetCountItemQuery, useGetStatusSystemQuery } from "../../../services/statisticApi";
import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

const STATUS_RUS = {
  'on': 'автомат',
  'off': 'выключен',
  'manual': 'ручной',
  'gate': 'калитка',
  'accident': 'авария'
}

const StateSystem: React.FC = () => {
  const {data:statusSys} = useGetStatusSystemQuery()
  const [statusSystem, setStatusSystem] = React.useState('неизвестно')
  const { timeRange, period } = useAppSelector((state) => state.statistic);
  const { data: currentCount } = useGetCountItemQuery({
    date_lte: timeRange?.date_end,
    date_gte: timeRange?.date_start,
  });
  React.useEffect(()=>{
    if (statusSys === 'on'){
      setStatusSystem('aвтомат')
    }
    if (statusSys === 'error'){
      setStatusSystem('ошибка')
    }
    if (statusSys === 'off'){
      setStatusSystem('выключен')
    }
    if (statusSys === 'manual'){
      setStatusSystem('ручной')
    }
    if (statusSys === 'gate'){
      setStatusSystem('калитка')
    }
    if (statusSys === 'accident'){
      setStatusSystem('aвария')
    }
  },[statusSys])
  const dataSource = [
    {
      key: "1",
      name: "Состояние системы",
      address: statusSystem
    },
    {
      key: "2",
      name: "Статус",
      address: "В работе",
    },
    {
      key: "3",
      name: "Количество отбраковок:",
      address: currentCount?.count ? currentCount.count : 0
    },
    {
      key: "4",
      name: "Последние изменения в системе",
      address: process.env.REACT_APP_UPDATE_CLIENT,
    },
  ];
  const columns = [
    {
      dataIndex: "name",
      key: "name",
    },

    {
      dataIndex: "address",
      key: "address",
    },
  ];
  return (
    <div className="flex flex-col justify-start items-start gap-1">
      <div className="flex w-full justify-between items-center">
        <CurrentTime />
        <div className="font-gilroy text-[10px] justify-end self-center">
          Версия ПО: {process.env.REACT_APP_VERSION}
        </div>
      </div>
      <Table
        className="flex font-gilroy-bold text-[15px]"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default StateSystem;
