import PageLayout from "./pages/PageLayout";
import Archive from "./pages/Archive";
import * as React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Statistic from "./pages/Statistic";
import { useAppSelector, useAppDispatch } from "./hooks/hooks";
import { RootState } from "./redux/store";
import Login from "./pages/Login";
import ModalMessage from "./components/ModalMessage/ModalMessage";
import RegistrationPage from "./pages/Registration";
import { setIsAuth } from "./redux/slices/userSlice";
import { useGetMeQuery, useUpdateTokensMutation } from "./services/userApi";
import MnemonicSchema from "./pages/MnemonicSchema";

export default function App() {
  return (
    <Routes>
      <Route element={<PageLayout />}>
        <Route
          path="/statistic"
          element={
            // <RequireAuth>
              <Statistic />
            // </RequireAuth>
          }
        />
        <Route
          path="/archive"
          element={
            <RequireAuth>
              <Archive />
            </RequireAuth>
          }
        />

        <Route
          path="/mnemonic-schema"
          element={
              <MnemonicSchema />
          }
        />
        <Route
          path="/logs"
          element={
            <RequireAuth>
              <Statistic />
            </RequireAuth>
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Statistic />
            </RequireAuth>
          }
        />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route
        path="/test"
        element={
          <ModalMessage
            type="success"
            title="Text very intressive"
            describe="Text very intressive abou smth else need write each other next driver"
            onOk={() => {}}
          />
        }
      />
    </Routes>
  );
}
// просто после токена отправлять на me и созранять данные о пользователе в стейт
// а запросы отправлять из локал стораджа
// в рек авто добавлять хеадер
// посмотреть на хранение функций в стейте

// function RequireAuth({ children }: { children: JSX.Element }) {
//   const dispath = useAppDispatch();
//   let auth = useAppSelector((state: RootState) => state.users.is_auth);
//   let location = useLocation();

//   const accessToken = localStorage.getItem("accessToken");
//   const {data:me} = useGetMeQuery()
//   const refreshToken = localStorage.getItem('refreshToken')
//   const [trigger, {data, isLoading, status}] = useUpdateTokensMutation()
//   console.log('aaaaa', refreshToken, accessToken)
//   console.log(me)
//   console.log('status', status)
//   React.useEffect(()=>{
//     if (accessToken){
//       const valid = me?.is_active
//       console.log('valid', valid)
//     if (valid){
//       dispath(setIsAuth(true))
//     }
//     if (!valid && refreshToken){
//       console.log('сработал рефрешь токен')
//       trigger(refreshToken)
//       console.log('после сработки')
//     }
//     if (!valid && !refreshToken){
//       dispath(setIsAuth(false))
//     }
//     }

    


//     },[])


//   React.useEffect(()=>{
//     if(data){
//       console.log('обновились токны')
//       console.log(data)
//       localStorage.setItem('refreshToken', data.refresh_token)
//       localStorage.setItem('accessToken', data.access_token)
//     }
//     else{
//       console.log('events')
//       localStorage.setItem('refreshToken','')
//       dispath(setIsAuth(false))
//     }
//   },[data])


//   if (!auth && !isLoading){
//     return <Navigate to='/login' state={{from: location}} replace/>
//   }  

  
//   return children;
// }


const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

function RequireAuth({ children }: { children: JSX.Element }) {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector((state: RootState) => state.users.is_auth);
  const location = useLocation();

  const [accessToken, setAccessToken] = React.useState<string | null>(localStorage.getItem(ACCESS_TOKEN_KEY));
  const [refreshToken, setRefreshToken] = React.useState<string | null>(localStorage.getItem(REFRESH_TOKEN_KEY));

  const { data: me, refetch: refetchMe } = useGetMeQuery(undefined, { skip: !accessToken });
  const [updateTokens, { data: newTokens, isLoading }] = useUpdateTokensMutation();
  // НУЖНО ВСЕ ПЕРЕДЕЛЫВАТЬ НЕ РАБОТАЕТ ОБНОВЛЕНИЕ РЕФРЕШЬ ТОКЕНА
  React.useEffect(() => {
    const checkAuth = async () => {
      

      if (accessToken) {
        const updateMe = refetchMe();
        try {
          const isValid = (await updateMe).data?.is_active
     
          if (isValid) {
            dispatch(setIsAuth(true));
          } 
        } catch{
          if (refreshToken) {
            try {
   
              const result = await updateTokens(refreshToken).unwrap();
              setAccessToken(result.access_token);
              setRefreshToken(result.refresh_token);
              localStorage.setItem(ACCESS_TOKEN_KEY, result.access_token);
              localStorage.setItem(REFRESH_TOKEN_KEY, result.refresh_token);
              await refetchMe();
            } catch (error) {
              console.error('Failed to refresh token:', error);
              dispatch(setIsAuth(false));
              setAccessToken(null);
              setRefreshToken(null);
              localStorage.removeItem(ACCESS_TOKEN_KEY);
              localStorage.removeItem(REFRESH_TOKEN_KEY);
            }
          } else {

            dispatch(setIsAuth(false));
          }
        }}
        else {

          dispatch(setIsAuth(false));
        }
        
      //   console.log('isValid', isValid)
      //   if (isValid) {
      //     dispatch(setIsAuth(true));
      //   } else if (refreshToken) {
      //     try {
      //       console.log('work try')
      //       const result = await updateTokens(refreshToken).unwrap();
      //       setAccessToken(result.access_token);
      //       setRefreshToken(result.refresh_token);
      //       localStorage.setItem(ACCESS_TOKEN_KEY, result.access_token);
      //       localStorage.setItem(REFRESH_TOKEN_KEY, result.refresh_token);
      //       await refetchMe();
      //     } catch (error) {
      //       console.error('Failed to refresh token:', error);
      //       dispatch(setIsAuth(false));
      //       setAccessToken(null);
      //       setRefreshToken(null);
      //       localStorage.removeItem(ACCESS_TOKEN_KEY);
      //       localStorage.removeItem(REFRESH_TOKEN_KEY);
      //     }
      //   } else {
      //     console.log('dispath 1')
      //     dispatch(setIsAuth(false));
      //   }
      // } else {
      //   console.log('dispath 2')
      //   dispatch(setIsAuth(false));
      // }
    };

    checkAuth();
  }, [accessToken, refreshToken, me, dispatch, updateTokens, refetchMe]);

  if (!isAuth && !isLoading) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
}