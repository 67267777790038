interface Props {
  children: JSX.Element;
}

export const StatisticSmallElem = (Props: Props) => {
  return (
    <div className="flex w-1/4 h-1/2">
      <div className="flex grow shrink justify-center bg-white rounded-xl shadow-md m-2 p-2">
        {Props.children}
      </div>
    </div>
  );
};

export default StatisticSmallElem;
