import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  type FormProps,
  Input,
} from "antd";
import { useCreateUserMutation, CreateUser } from "../../../services/userApi";
import { set } from "date-fns";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { setModal } from "../../../redux/slices/userSlice";
import { useAppDispatch } from "../../../hooks/hooks";

type FieldType = {
  username?: string;
  email?: string;
  password?: string;
  password2?: string;
  firstName?: string;
  lastName?: string;
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
  errorInfo
) => {};

const Registration: React.FC = () => {
  const [CreateUser] = useCreateUserMutation();
  const dispatch = useAppDispatch();
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    if (
      values.email &&
      values.password &&
      values.username &&
      values.firstName &&
      values.lastName
    ) {
      CreateUser({
        email: values.email,
        login: values.username,
        password: values.password,
        first_name: values.firstName,
        last_name: values.lastName,
        role_id: 1,
      });
      dispatch(setModal(true));
    }
  };

  return (
    <div className="flex flex-col w-[620px] grow rounded-[10px] bg-white p-5">
      <div className="font-gilroy text-[#9295AB]"> Город, предприятие</div>
      <div className="flex flex-col font-gilroy-bold text-black text-[32px] items-center justify-center py-6">
        Регистрация
        <div className="w-[450px] px-10">
          <ConfigProvider
            theme={{
              components: {
                Form: {
                  labelFontSize: 20,
                  itemMarginBottom: 10,
                  labelColonMarginInlineEnd: 10,
                },
              },
              token: {},
            }}
          >
            <Form
              name="basic"
              // labelCol={{ span: 8 }}
              // wrapperCol={{ span: 16 }}
              style={{ maxWidth: 450 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="on"
              layout="vertical"
              className="font-gilroy"
            >
              <Form.Item<FieldType>
                label="Придумайте логин"
                name="username"
                rules={[{ message: "Please input your username!" }]}
                className="font-gilroy "
              >
                <Input
                  variant="filled"
                  className="font-gilroy font-semibold text-[20px] h-[40px]"
                />
              </Form.Item>
              <Form.Item<FieldType>
                label="Введите ваш email"
                name="email"
                rules={[
                  { message: "Please input your emale!" },
                  {
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Please enter a valid email address!",
                  },
                ]}
              >
                <Input
                  variant="filled"
                  className="font-gilroy font-semibold text-[20px] h-[40px]"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label="Ваше имя"
                name="firstName"
                rules={[{ message: "Please input your emale!" }]}
              >
                <Input
                  variant="filled"
                  className="font-gilroy font-semibold text-[20px] h-[40px]"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label="Ваша фамилия"
                name="lastName"
                rules={[{ message: "Please input your emale!" }]}
              >
                <Input
                  variant="filled"
                  className="font-gilroy font-semibold text-[20px] h-[40px]"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label="Придумайте пароль"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password
                  variant="filled"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  className="font-gilroy font-semibold text-[16px] h-[40px]"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label="Повторите пароль"
                name="password2"
                dependencies={["password"]}
                rules={[
                  { required: true, message: "Please retry your password!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  variant="filled"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  className="font-gilroy font-semibold text-[16px] h-[40px]"
                />
              </Form.Item>
              <Form.Item className="flex justify-center items-center mt-5">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="flex justify-center items-center px-[40px] font-gilroy-bold text-[20px] h-[50px] mt-5 bg-[blue]"
                >
                  Зарегестрироваться
                </Button>
              </Form.Item>
            </Form>
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default Registration;
