import { configureStore } from "@reduxjs/toolkit";
import eventReducer from "./slices/eventSlice";
import userReducer from "./slices/userSlice";
import statisticReducer from "./slices/statisticSlice";
import { eventsApi } from "../services/eventApi";
import { otherApi } from "../services/otherApi";
import { authApi } from "../services/userApi";
import { statisticApi } from "../services/statisticApi";

export const store = configureStore({
  reducer: {
    events: eventReducer,
    users: userReducer,
    statistic: statisticReducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [otherApi.reducerPath]: otherApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [statisticApi.reducerPath]: statisticApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(eventsApi.middleware)
      .concat(otherApi.middleware)
      .concat(authApi.middleware)
      .concat(statisticApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
