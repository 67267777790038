import StatisticSmallElem from "../components/StatisticElem/StatisticSmallElem";
import MainInfo from "../components/StatisticElem/StatisticElems/MainInfo/MainInfo";
import StateSystem from "../components/StatisticElem/StatisticElems/StateSystem";
import StatisticEvents from "../components/StatisticElem/StatisticElems/StatisticsEvents";
import StatisticCameras from "../components/StatisticElem/StatisticElems/StatisticCameras";
import OpenArchive from "../components/StatisticElem/StatisticElems/OpenArchive";
import StatisticBigElem from "../components/StatisticElem/StatisticBigElem";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import React from "react";
import { setTimeRange } from "../redux/slices/statisticSlice";
import { useGetShiftsRangeQuery } from "../services/eventApi";

import StatisticGeoLine from "../components/StatisticElem/StatisticGeoLine";

const Statistic = () => {
  const period = useAppSelector((state) => state.statistic.period);
  const dispatch = useAppDispatch();
  const { data: TimeShifts } = useGetShiftsRangeQuery();
  const dataTest = useAppSelector((state) => state.statistic.timeRange);
  React.useEffect(() => {
    switch (period) {
      case "day":
        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999);
        dispatch(
          setTimeRange({
            date_start: startOfDay.toISOString(),
            date_end: endOfDay.toISOString(),
          })
        );
        break;
      //Здесь выполняются инструкции, если результат выражения равен value1

      case "shift1":
        if (TimeShifts) {
          dispatch(
            setTimeRange({
              date_start: TimeShifts.current.start,
              date_end: TimeShifts.current.end,
            })
          );
        }
        break;
      case "shift2":
        if (TimeShifts) {
          dispatch(
            setTimeRange({
              date_start: TimeShifts.previous.start,
              date_end: TimeShifts.current.end,
            })
          );
        }
        break;
      case "hour":
        const now = new Date();
        now.setHours(now.getHours() - 1);
        const oneHourAgo = now.toISOString();
        dispatch(
          setTimeRange({
            date_start: oneHourAgo,
            date_end: new Date().toISOString(),
          })
        );
        break;
      default:
        break;
    }
  }, [period, TimeShifts]);

  return (
    <div>
    <div>СТРАНИЦА НАХОДИТСЯ В РАЗРАБОТКЕ</div>
    <div className="flex flex-wrap h-[calc(100vh-130px)] w-full">
      <MainInfo />
      <StatisticBigElem children={<OpenArchive />} />
      {/* <StatisticBigElem children={ <StatisticGeoLine line="line_1" geo="width"/>}/> */}
      {/* <StatisticBigElem children={ <StatisticGeoLine line="line_2" geo="length"/>}/> */}
      {/* <StatisticBigElem children={ <StatisticGeoLine line="line_2" geo="width"/>}/> */}
      <StatisticSmallElem children={<StatisticEvents />} />
      <StatisticSmallElem children={<StatisticCameras />} />
      <StatisticSmallElem children={<StateSystem />} />
    </div>
    </div>
  );
};

export default Statistic;
