import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface TimeRange {
  date_start?: string;
  date_end?: string;
}

export interface InitialState {
  period: "hour" | "shift1" | "shift2" | "day";
  timeRange: TimeRange;
  count: number;
}

export const statisticSlice = createSlice({
  name: "statistic",
  initialState: <InitialState>{
    period: "day",
    timeRange: {},
  },
  reducers: {
    setPeriod: (
      state: InitialState,
      action: PayloadAction<"hour" | "shift1" | "shift2" | "day">
    ) => {
      state.period = action.payload;
    },
    setCount: (state: InitialState, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setTimeRange: (state: InitialState, action: PayloadAction<TimeRange>) => {
      state.timeRange = action.payload;
    },
  },
});

export const { setPeriod, setCount, setTimeRange } = statisticSlice.actions;
export default statisticSlice.reducer;
