import React from "react";
import ReactDOM from "react-dom";
import { Pie } from "@ant-design/plots";
import {
  useGetGroupByDefectQuery,
  useGetGropByIntervalQuery,
  useGetGroupByCamerasQuery,
} from "../../../services/statisticApi";
import { useAppSelector } from "../../../hooks/hooks";

interface DataItemDisplayed {
  type: string;
  procent: number;
  count: number;
}
const StatisticEvents: React.FC = () => {
  const { timeRange, count } = useAppSelector((state) => state.statistic);
  const { data: DataByDefect } = useGetGroupByDefectQuery({
    date_lte: timeRange?.date_end,
    date_gte: timeRange?.date_start,
  });
  const [dataDisplayed, setDataDisplayed] = React.useState<DataItemDisplayed[]>(
    []
  );
  React.useEffect(() => {
    if (DataByDefect) {
      const data = DataByDefect.items.map((item) => ({
        type: item._id,
        procent: Math.round((item.count / count) * 100),
        count: item.count,
      }));
      setDataDisplayed(data);
    }
  }, [DataByDefect, count]);
  const config = {
    angleField: "procent",
    colorField: "type",
    paddingRight: 70,
    radius: 0.8,
    innerRadius: 0.5,
    label: {
      text: "count",
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      color: {
        title: false,
        position: "right",

        rowPadding: 5,
      },
    },
    annotations: [
      {
        type: "text",
        style: {
          text: "Типы дефектов",
          x: "50%",
          y: "50%",
          textAlign: "center",
          fontSize: 30,
          fontStyle: "bold",
        },
      },
    ],
  };
  return (
    <Pie
      className="basis-10/12"
      forceUpdate={true}
      data={dataDisplayed}
      {...config}
    />
  );
};

export default StatisticEvents;
