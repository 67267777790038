import React from "react";

function CurrentTime() {
  const [date, setDate] = React.useState(new Date());

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex gap-1 flex-col justify-center items-center">
      <p className="text-[20px]">{date.toLocaleTimeString()}</p>
      <p className="text-[20px]">{date.toLocaleDateString("ru-Ru")}</p>
    </div>
  );
}

export default CurrentTime;
