import React from "react";
import { Image, Stage, Layer, Rect, Text } from "react-konva";
import { COCODataset, COCOImage } from "../../../types/COCO";
import { useAppSelector } from "../../../hooks/hooks";
import { RootState } from "../../../redux/store";
import getBackend from "../../../services/utils/getBackend";

// добавить состояние по слою
interface Props {
  dataset: COCODataset;
  camera: string;
  width: number;
  height: number;
  description: string
  // imageId: number
}

interface ColorBox{
  [key: number]: [number, number, number, number];
}
const ColorsBox: ColorBox  = {
  0: [255, 0, 0, 0.2],
  1: [0, 0, 255, 0.2],
  2: [0, 255, 0, 0.2],
  3: [109, 110, 113, 0.2],
  4: [255, 255, 0, 0.2],
}
// добавить состояние по слою
const ImageAnnotationViewer: React.FC<Props> = ({
  dataset,
  camera,
  width,
  height,
  description
}) => {
  const maskActive = useAppSelector(
    (state: RootState) =>
      state.events.archiveButtonActive.buttonsImageState.mask
  );

  const [resizeCoef, setResizeCoef] = React.useState(1);
  const [image, setImage] = React.useState<COCOImage>({
    id: 1,
    width: 0,
    height: 0,
    file_name: "top_line_camera/defect_top_line_camera_1_d0e5493cf7_2024-09-25 17:00:08.808801.png", // bug
  });
  React.useEffect(() => {
    const imageF = dataset.images.find((image) => image.id === 1);
    if (imageF) {
      setImage(imageF);
    }
  }, [dataset]);

  React.useEffect(() => {
    if (width / image.width < 1) {
      setResizeCoef(
        width / image.width
      );
    }
  }, [width, height, image]);

  const annotations = dataset.annotations.filter((a) => a.image_id === 1);
  const KonvaImage = new window.Image();

  KonvaImage.src = `${getBackend()}static/img/${image.file_name}`;
  return (
    <Stage
      scale={{ x: resizeCoef, y: resizeCoef }}
      width={width}
      height={height}
      className="mt-[300px]"
    >
      <Layer>
        <Image image={KonvaImage} />
      </Layer>
      <Layer>
        {maskActive &&
          annotations.map((annotation, idx) => (
            <>
              <Rect
                key={idx}
                x={annotation.bbox[0]}
                y={annotation.bbox[1]}
                width={annotation.bbox[2]}
                height={annotation.bbox[3]}
                stroke={`rgba(${ColorsBox[idx][0]}, ${ColorsBox[idx][1]}, ${ColorsBox[idx][2]})`}
                fill={`rgba(${ColorsBox[idx][0]}, ${ColorsBox[idx][1]}, ${ColorsBox[idx][2]}, ${ColorsBox[idx][3]})`}
              />
            {/* {idx===0 && description.split('\\n').map((text, idxDesc) => {

              {Array.from(text).map((letter, idxLet) =>{

                return(
                  <Text
                  key={idxLet}
                  x={annotation.bbox[0]}
                  y={annotation.bbox[1]}
                  text={letter.toUpperCase()}
                  fill={ letter==='?' ? 'red' : "white" }
                  fontSize={40}
                />)
              })               
            }})
            } */}
            {idx === 0 && description.split('\\n').map((text, idxDesc) => (
                <React.Fragment key={idxDesc}>
                  {Array.from(text).map((letter, idxLet) => (
     
                    <Text
                      key={`${idxDesc}-${idxLet}`}
                      x={annotation.bbox[0] + idxLet * 25 + 40} 
                      y={annotation.bbox[1] + annotation.bbox[3] - ((description.split('\\n').length - idxDesc) * 50)}
                      text={letter.toUpperCase()}
                      fill={letter === '?' ? 'red' : "white"}
                      fontSize={40}
                      fontStyle='bold'
                      letterSpacing={40}
                      
                    />
                  ))}
                </React.Fragment>
                  ))}




            </>
          ))}
      </Layer>
    </Stage>
  );
};

export default ImageAnnotationViewer;
