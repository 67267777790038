import React from "react";
import type { CollapseProps, TableColumnsType } from "antd";
import { Collapse, ConfigProvider } from "antd";
import { RootState } from "../../../redux/store";
import CameraFilter from "./CameraFilter/CameraFilter";
import TypeDefectFilter from "./TypeDefectFilter/TypeDefectFilter";
import StatusFilter from "./StatusFilter/StatusFilter";
import DateTimePicker from "./DateTimePicker/DateTimePicker";
import MaterialsFilter from "./MaterialsFilter/MaterialsFilter";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";

import StateFilterView from "./StateFilterView/StateFilterView";
import { setBlockInfo } from "../../../redux/slices/eventSlice";

interface Props {
  size: number;
}
const AdditionalFilterBlock = ({ size }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  dispatch(setBlockInfo("Фильтр"));
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Камера",
      children: <CameraFilter />,
    },
    {
      key: "2",
      label: "Тип дефекта",
      children: <TypeDefectFilter />,
    },
    {
      key: "3",
      label: "Статус",
      children: <StatusFilter />,
    },
    {
      key: "4",
      label: "Дата и время",
      children: <DateTimePicker />,
    },
    {
      key: "5",
      label: "Материал",
      children: <MaterialsFilter />,
    },
  ];
  const sizeItem = `h-[${size}px]`;
  // <Panel header="This is panel header 1" key="1">
  return (
    <div
      className={`flex grow flex-col self-start m-1`}
      // style={{ overflow: "scroll", height: sizeItem }}
    >
      <StateFilterView />
      <div
      // className={`flex grow  ${sizeItem}`}
      // style={{ overflow: "scroll", height: sizeItem }}
      >
        <ConfigProvider
          theme={{
            token: {
              // colorBgContainer: 'bg-white',
              borderRadius: 0,
              // colorBorder: 'black',
              // colorTextHeading: '#9295AB',
            },
            components: {
              Collapse: {
                contentPadding: 0,
                headerBg: "#EDEFF2",
                contentBg: "white",
              },
            },
          }}
        >
          <Collapse
            bordered={false}
            className="justify-start font-gilroy-bold text-[18px]"
            // expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            defaultActiveKey={["1"]}
            items={items}
            expandIconPosition={"end"}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default AdditionalFilterBlock;
