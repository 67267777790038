import { MainFilter, QuickFilter } from "../../redux/slices/eventSlice";
import { MainFilterQuery } from "../eventApi";

function createQueryFilter(
  mainFilter: MainFilter
): MainFilterQuery {
  const typeEvents = mainFilter.type
    ? mainFilter.type.map((item) => {
        return item.id;
      })
    : [];
  const statuses = mainFilter.status
    ? mainFilter.status.map((item) => {
        return item.id;
      })
    : [];
  const materials__in = mainFilter.materials__in
    ? mainFilter.materials__in.map((item) => {
        return item.id;
      })
    : [];
  const cameras__in = mainFilter.cameras__in
    ? mainFilter.cameras__in.map((item) => {
        return item.id;
      })
    : [];

  const newObj: MainFilterQuery = {};

  if (typeEvents.length > 0) {
    newObj.type = typeEvents;
  }
  if (statuses.length > 0) {
    newObj.status = statuses;
  }
  if (materials__in.length > 0) {
    newObj.materials__in = materials__in;
  }
  if (cameras__in.length > 0) {
    newObj.cameras__in = cameras__in;
  }
  newObj.date_gte = mainFilter.date_gte
  newObj.date_lte = mainFilter.date_lte

  return newObj;
}

export default createQueryFilter;
