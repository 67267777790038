import { Checkbox } from "antd";
import { useGetStatusesQuery } from "../../../../services/otherApi";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
import { setStatusFilter } from "../../../../redux/slices/eventSlice";
import { Status } from "../../../../types/event";

const StatusFilter = (): JSX.Element => {
  const { data: statuses } = useGetStatusesQuery({});
  const dispatch = useAppDispatch();
  const statusesFilter = useAppSelector(
    (state: RootState) => state.events.boxForFilter.status
  );

  const onChange = (e: CheckboxChangeEvent, statusFilter: Status) => {
    if (e.target.checked) {
      dispatch(setStatusFilter([...statusesFilter, statusFilter]));
    } else {
      dispatch(
        setStatusFilter([
          ...statusesFilter.filter((item) => item !== statusFilter),
        ])
      );
    }
  };
  return statuses ? (
    <div className="flex flex-wrap gap-4 bg-[#F5F6F7] justify-start p-5 font-gilroy-bold">
      {statuses.map((status) => {
        return (
          <div className="">
            <Checkbox
              className="flex shadow justify-start items-center w-[270px] h-[60px] bg-white rounded-[5px] font-gilroy-bold p-3 gap-1"
              onChange={(e) => {
                onChange(e, status);
              }}
              checked={statusesFilter.includes(status)}
            >
              {status.name}{" "}
            </Checkbox>
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};
export default StatusFilter;
