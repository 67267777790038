import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Event, EventType, Status, Material, Camera } from "../../types/event";
import { getEndOfDay, getStartOfDay } from "../../services/utils/getTimeOfDate";
import { getPeriodHalfDay } from "../../components/AdditionalBlock/AdditionalArchiveBlock/СlockTimeLine/utils/utils";

export interface MainFilter {
  type: EventType[];
  status: Status[];
  materials__in: Material[];
  cameras__in: Camera[];
  date_gte: string;
  date_lte: string;
}

export interface QuickFilter {
  date_gte: string | undefined;
  date_lte: string | undefined;
}

export interface ArchiveFrameActive {
  clockFrame: boolean;
  filterFrame: boolean;
  listEventsFrame: boolean;
}

// export interface DependentIconItem {
//   archive: boolean
//   // information: boolean
//   // statistics: boolean
//   filterForEvents: boolean
//   listItems: boolean

// }

export interface BurgerFrameActive {
  statistic: boolean;
  imageViewer: boolean;
  baseKnowledge: boolean;
}

export interface ButtonsImageState {
  mask: boolean;
  saveImage: boolean;
}

export interface ArchiveButtonActive {
  buttonsImageState: ButtonsImageState;
  archiveFrameActive: "clockFrame" | "listEventsFrame";
  filterFrameActive: boolean;
}

export interface InitialState {
  filteredEvents: Event[];
  activeIndex: number;
  activeEvent: Event | null;
  mainFilter: MainFilter;
  isFilterOn: boolean;
  boxForFilter: MainFilter;
  burgerFrameActive: BurgerFrameActive;
  archiveButtonActive: ArchiveButtonActive;
  // activeAdditionalBlock: boolean
  blockInfo: string;
  autoScroll: boolean;
}
const period = getPeriodHalfDay(new Date())
export const eventSlice = createSlice({
  name: "events",
  initialState: <InitialState>{
    blockInfo: "Статистика",
    filteredEvents: [],
    activeEvent: null,
    activeIndex: -1,
    // activeAdditionalBlock: false,
    mainFilter: {
      date_lte: period.end.toISOString(),
      date_gte: period.start.toISOString(),
      type: [],
      status: [],
      materials__in: [],
      cameras__in: [],
    },
    isFilterOn: false,
    boxForFilter: {
      date_lte: period.end.toISOString(),
      date_gte: period.start.toISOString(),
      type: [],
      status: [],
      materials__in: [],
      cameras__in: [],
    },
    // quickFilter: {
    //   date_lte: getEndOfDay({}),
    //   date_gte: getStartOfDay({}),
    // },
    burgerFrameActive: {
      statistic: true,
      imageViewer: false,
      baseKnowledge: false,
    },
    archiveButtonActive: {
      buttonsImageState: { mask: false, saveImage: false },
      archiveFrameActive: "clockFrame",
      filterFrameActive: false,
    },
    autoScroll: true,
  },

  reducers: {
    setAutoScroll: (state: InitialState, action: PayloadAction<boolean>) => {
      state.autoScroll = action.payload;
    },
    setActiveEvent: (state: InitialState, action: PayloadAction<Event>) => {
      state.activeEvent = action.payload;
    },
    setFiltredEvent: (state: InitialState, action: PayloadAction<Event[]>) => {
      state.filteredEvents = action.payload;
    },
    setActiveIndex: (state: InitialState, action: PayloadAction<number>) => {
      state.activeIndex = action.payload;
    },
    setFilterFrameActive: (
      state: InitialState,
      action: PayloadAction<boolean>
    ) => {
      state.archiveButtonActive.filterFrameActive = action.payload;
    },
    setIsFilterOn: (
      state: InitialState,
      action: PayloadAction<boolean>
    ) => {
      state.isFilterOn = action.payload;
    },
    decrementActiveIndex: (state: InitialState) => {
      state.activeIndex = state.activeIndex - 1;
    },
    incrementActiveIndex: (state: InitialState) => {
      state.activeIndex = state.activeIndex + 1;
    },
    setMainFilter: (state: InitialState, action: PayloadAction<MainFilter>) => {
      state.mainFilter = action.payload;
    },
    setBoxToMainFilter: (
      state: InitialState,
      action: PayloadAction<MainFilter>
    ) => {
      state.boxForFilter = action.payload;
    },
    // setQuickFilter: (
    //   state: InitialState,
    //   action: PayloadAction<QuickFilter>
    // ) => {
    //   state.quickFilter = action.payload;
    // },
    setBurgerState: (
      state: InitialState,
      action: PayloadAction<BurgerFrameActive>
    ) => {
      state.burgerFrameActive = action.payload;
    },
    setButtonsImageState: (
      state: InitialState,
      action: PayloadAction<ButtonsImageState>
    ) => {
      state.archiveButtonActive.buttonsImageState = action.payload;
    },
    setArchiveFrameActive: (
      state: InitialState,
      action: PayloadAction<ArchiveButtonActive["archiveFrameActive"]>
    ) => {
      state.archiveButtonActive.archiveFrameActive = action.payload;
    },
    // setActiveAdditionalBlock: (
    //   state: InitialState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.activeAdditionalBlock = action.payload
    // },
    setCamerasFilter: (
      state: InitialState,
      action: PayloadAction<Camera[]>
    ) => {
      state.boxForFilter.cameras__in = action.payload;
    },
    setTypeFilter: (
      state: InitialState,
      action: PayloadAction<EventType[]>
    ) => {
      state.boxForFilter.type = action.payload;
    },
    setStatusFilter: (state: InitialState, action: PayloadAction<Status[]>) => {
      state.boxForFilter.status = action.payload;
    },
    setMaterialFilter: (
      state: InitialState,
      action: PayloadAction<Material[]>
    ) => {
      state.boxForFilter.materials__in = action.payload;
    },
    setDateFromFilter: (state: InitialState, action: PayloadAction<string>) => {
      state.boxForFilter.date_gte = action.payload;
    },
    setDateToFilter: (state: InitialState, action: PayloadAction<string>) => {

      state.boxForFilter.date_lte = action.payload;
    },
    setBlockInfo: (state: InitialState, action: PayloadAction<string>) => {
      state.blockInfo = action.payload;
    },
  },
});

export const {
  decrementActiveIndex,
  incrementActiveIndex,
  setActiveEvent,
  setFiltredEvent,
  setActiveIndex,
  setMainFilter,
  setBurgerState,
  setMaterialFilter,
  setCamerasFilter,
  // setQuickFilter,
  setStatusFilter,
  setTypeFilter,
  setDateFromFilter,
  setDateToFilter,
  setButtonsImageState,
  setArchiveFrameActive,
  setFilterFrameActive,
  setBoxToMainFilter,
  setBlockInfo,
  setAutoScroll,
  setIsFilterOn
} = eventSlice.actions;
export default eventSlice.reducer;
