import React from "react";
import { MainFilterQuery } from "../../../../services/eventApi";
import { Stage, Image, Layer, Circle, Line, Text } from "react-konva";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useGetEventsQuery } from "../../../../services/eventApi";
import { TimeLineEvent, getEventsXAxisCircle } from "./utils/utils";
import {
  decrementActiveIndex,
  incrementActiveIndex,
  setActiveEvent,
  setActiveIndex,
  setAutoScroll,
} from "../../../../redux/slices/eventSlice";
import useImage from "use-image";

import ArrowLeft from "./NavigationArrowForward/assets/img/arrow_forward2.svg";
import ArrowRight from "./NavigationArrowForward/assets/img/arrow_forward.svg";

import createQueryFilter from "../../../../services/utils/getQueryString";
import { RootState } from "../../../../redux/store";
import { NavigationArrowForward } from "./NavigationArrowForward/NavigationArrowForward";
import logoMain from "./is_logo_kontur.png"
import imageCompas from "./output-onlinepngtools_2.png";
import { useGetCamerasQuery, useGetEventTypeQuery } from "../../../../services/otherApi";
const CompassImage = () => {
  const [image] = useImage(imageCompas);
  return <Image image={image} x={-137} y={-118} width={700} height={700} />;
};
const LogoImage = () => {
  const [image] = useImage(logoMain);
  return <Image image={image} x={200} y={320} width={50} height={35} />;
};


function getPrettyDate(date: Date) {
  const timestamp = new Date(date);
  const new_date = timestamp.toLocaleDateString();
  const time = timestamp.toLocaleTimeString();
  return `${time}`;
}

export const Clock: React.FC = () => {
  const { activeIndex, mainFilter, activeEvent, autoScroll } = useAppSelector(
    (state: RootState) => state.events
  );

  const dispatch = useAppDispatch();
  const [mainFilterQuery, setMainFilterQuery] = React.useState<MainFilterQuery>(
    createQueryFilter(mainFilter)
  );
  const {data: filteredEvents} = useGetEventsQuery(mainFilterQuery, {
    pollingInterval: 3000
  }); 
  const {data: cameras} = useGetCamerasQuery({})
  const {data: eventType} = useGetEventTypeQuery()
  console.log(cameras)
  const eventsLength = filteredEvents ? filteredEvents.length : -1;
  React.useEffect(() => {
    setMainFilterQuery(createQueryFilter(mainFilter));
  }, [mainFilter]);
  React.useEffect(() => {
    if (filteredEvents && activeEvent && !autoScroll) {
      const newActiveIndex = filteredEvents.findIndex((item) => item._id === activeEvent._id)
      if (newActiveIndex >=0){
        dispatch(setActiveIndex(newActiveIndex));
      }
    }
    else {
      if (filteredEvents){
        dispatch(setActiveIndex(filteredEvents.length - 1))  
      }
    }
  
  }, [filteredEvents,mainFilterQuery]);

  const decrementIndex = () => {
    if (activeIndex > 0) {
      dispatch(decrementActiveIndex());
    }
  };

  const incrementIndex = () => {
    if (activeIndex < eventsLength - 1) {
      dispatch(incrementActiveIndex());
    }
  };

  const eventsInCircle = filteredEvents
    ? getEventsXAxisCircle({ events: filteredEvents})
    : [];

  const onClickLine = (event: TimeLineEvent, idx: number) => {
    dispatch(setActiveEvent(event.event));
    dispatch(setActiveIndex(idx));
  };
  return (
    <div className="flex w-max">
      <div className="min-w-[60px]">
      {activeIndex < eventsLength - 1 && (
       
          <NavigationArrowForward imgUrl={ArrowLeft} onClick={incrementIndex} />
        )}
      </div>
    
      <Stage width={460} height={460}>
        <Layer>
          <Circle x={230} y={230} radius={230} fill="#EEEEEE" />
          {eventsInCircle
            .filter((i) => i.lineInClock >= 0)
            .map((_, idx) => (
              <Line
                key={idx}
                points={[
                  230,
                  230,
                  230 - 230 * Math.cos(_.lineInClock),
                  230 - 230 * Math.sin(_.lineInClock),
                ]}
                stroke= { _.event._id !== activeEvent?._id ? "#8DA7FF":  "#b81414"}
                strokeWidth={2}
                onClick={() => {
                  onClickLine(_, idx);
                }}
              />
            ))}
          <Circle x={230} y={230} radius={210} fill="white" />
          {/* <CompassImage /> */}
          <LogoImage />
          <Text
            text='3'
            y={200}
            x={390}
            fontSize={70}
            fontFamily="font-mono"
            fontStyle="bold"
            fill="#9295AB"
          />
                    <Text
            text='6'
            y={370}
            x={205}
            fontSize={70}
            fontFamily="font-mono"
            fontStyle="bold"
            fill="#9295AB"
          />
                    <Text
            text='9'
            y={200}
            x={40}
            fontSize={70}
            fontFamily="font-mono"
            fontStyle="bold"
            fill="#9295AB"
          />
                    <Text
            text='12'
            y={30}
            x={190}
            fontSize={70}
            fontFamily="font-mono"
            fontStyle="bold"
            fill="#9295AB"
          />
          {eventsInCircle
            .filter((i) => i.lineInClock >= 0)
            .map((_, idx) => (
              <Line
                key={idx}
                points={[
                  230,
                  230,
                  230 - 230 * Math.cos(_.lineInClock),
                  230 - 230 * Math.sin(_.lineInClock),
                ]}
                stroke= { _.event._id !== activeEvent?._id ? "#8DA7FF":  "#b81414"}
                opacity={0}
                strokeWidth={3}
                onClick={() => {
                  onClickLine(_, idx);
                }}
              />
            ))}
            {/* {eventsInCircle
            .filter((i) => i.lineInClock >= 0)
            .map((_, idx) => (
              <Line
                key={idx}
                points={[
                  230,
                  230,
                  230 - 230 * Math.cos(_.lineInClock),
                  230 - 230 * Math.sin(_.lineInClock),
                ]}
                opacity={0}
                strokeWidth={5}
                stroke= { _.event._id !== activeEvent?._id ? "#8DA7FF":  "#b81414"}
                onClick={() => {
                  onClickLine(_, idx);
                }}
              />
            ))} */}
          <Circle x={230} y={230} radius={210} fill="#00990055" opacity={0} />
          <Text
            text={`Камера: ${activeEvent ? cameras?.find((item) =>item.id === activeEvent.camera)?.caption : "-"}`}
            y={210}
            x={90}
            fontSize={22}
            fontFamily="Gilroy"
            fontStyle="normal"
            fill="#9295AB"
          />
          <Text
            text={
              activeEvent?.created ? getPrettyDate(activeEvent.created) : "-"
            }
            y={150}
            x={150}
            fontSize={50}
            fill="#194DFF"
            fontFamily="Gilroy-Bold"
            fontStyle="bold"
          />
          <Text
            text={`Тип дефекта: ${activeEvent ? eventType?.find((item) =>item.id === activeEvent.type)?.caption : "-"}`}
            y={240}
            x={90}
            fontSize={22}
            fontFamily="Gilroy-Bold"
            fill="#6B7A99"
          />
        </Layer>
      </Stage>

      <div className="min-w-[60px]">

        {activeIndex > 0 && (
          <NavigationArrowForward
            imgUrl={ArrowRight}
            onClick={decrementIndex}
          />
        )}
      </div>
    </div>
  );
};
