import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Event, EventType, Status, Material, Camera } from "../../types/event";
import { getEndOfDay, getStartOfDay } from "../../services/utils/getTimeOfDate";
import {getLocalAccesToken, getLocalRefreshToken} from "../../services/utils/getToken";

export interface InitialState {
  is_auth: boolean;
  modal: boolean;
}

export const userSlice = createSlice({
  name: "events",
  initialState: <InitialState>{
    is_auth: getLocalAccesToken() !== null,
    modal: false,
  },
  reducers: {
    setIsAuth: (state: InitialState, action: PayloadAction<boolean>) => {
      state.is_auth = action.payload;
    },
    setModal: (state: InitialState, action: PayloadAction<boolean>) => {
      state.modal = action.payload;
    },
  },
});
export const { setIsAuth, setModal } = userSlice.actions;
export default userSlice.reducer;
