import {
  useGetOneEventsQuery,
} from "../../../services/eventApi";
import ImageAnnotationViewer from "../ImageAnnotationViewer/ImageAnnotationViewer";


import React, { useRef } from "react";

interface PropsImage {
  id: string;
}

export const EventImage = (Props: PropsImage): JSX.Element => {
  const { data } = useGetOneEventsQuery(Props.id);
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const ref = useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.clientHeight);
    }
  }, [ref]);

  return (
    <div
      className="flex h-full self-center items-center justify-center"
      ref={ref}
    >
      {data && (
        <ImageAnnotationViewer
          width={width}
          height={height}
          dataset={data.coco}
          camera={data.camera}
          description={data.type==='flex_defect' ? data.description : ''}
        />
      )}
    </div>
  );
};

export default EventImage;
