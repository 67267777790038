import React from "react";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { ConfigProvider, TimePicker } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAppSelector, useAppDispatch } from "../../../../../hooks/hooks";
import {
  setDateFromFilter,
  setDateToFilter,
} from "../../../../../redux/slices/eventSlice";
dayjs.extend(customParseFormat);
const format = "HH:mm";

const LocalTimePicker = (): JSX.Element => {
  const [valueFrom, setFromValue] = React.useState<Dayjs | null>(null);
  const [valueTo, setFromTo] = React.useState<Dayjs | null>(null);
  const dispatch = useAppDispatch();
  const { boxForFilter } = useAppSelector((state: any) => state.events);

  const onChangeFromTime = (time: Dayjs) => {
    const dateFromString: string = boxForFilter.date_gte;
    const dateFrom = new Date(Date.parse(dateFromString));
    if (time) {
      dateFrom.setHours(time.hour(), time.minute(), 0);

      dispatch(setDateFromFilter(dateFrom.toISOString()));
    }
  };

  const onChangeToTime = (time: Dayjs) => {
    const dateToString: string = boxForFilter.date_lte;
    const dateTo = new Date(Date.parse(dateToString));
    if (time) {
      dateTo.setHours(time.hour(), time.minute(), 0);

      dispatch(setDateToFilter(dateTo.toISOString()));
    }
  };

  return (
    <div className="flex justify-start px-3 gap-5">
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Gilroy-Regular",
          },
        }}
      >
        <TimePicker
          className="shadow font-gilroy w-[200px] h-[40px] rounded text-black"
          autoFocus={true}
          onChange={onChangeFromTime}
          defaultOpenValue={dayjs("00:00", "HH:mm")}
          format={format}
          placeholder="С __: __"
        />
      </ConfigProvider>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Gilroy-Regular",
          },
        }}
      >
        <TimePicker
          className="shadow font-gilroy w-[200px] h-[40px] rounded text-black"
          autoFocus={true}
          onChange={onChangeToTime}
          defaultOpenValue={dayjs("00:00", "HH:mm")}
          format={format}
          placeholder="По __: __"
        />
      </ConfigProvider>
    </div>
  );
};

export default LocalTimePicker;
