import { useGetLinesQuery } from '../../../../services/otherApi'
import CameraLineList from './CameraLineList/CameraLineList'

const CameraFilter: React.FC = () => {
  const { data: lines } = useGetLinesQuery()

  return lines ? (
    <div className='flex flex-col bg-[#F5F6F7] rounded-[5px]'>
      {lines.map((line) => (
        <CameraLineList lineId={line.name} key={line.name} />
      ))}
    </div>
  ) : (
    <></>
  )
}
export default CameraFilter
