import React from "react";
import { useAppSelector, useAppDispatch } from "../../../../hooks/hooks";
import {
  MainFilter,
  setMainFilter,
  setBoxToMainFilter,
  setFilterFrameActive,
  setDateToFilter,
  setDateFromFilter,
  setArchiveFrameActive,
} from "../../../../redux/slices/eventSlice";
import {
  getEndOfDay,
  getStartOfDay,
} from "../../../../services/utils/getTimeOfDate";
import ButtonClearFilter from "./ButtonClearFilter";
import FilterItemView from "./FilterItemView";
import createQueryFilter from "./utils/getFilterItemArray";
import Button from "../../../Button/Button";
import removeItemFilter from "./utils/removeItemFromFilter";

const StateFilterView = () => {
  const dispatch = useAppDispatch();
  const { mainFilter, boxForFilter } = useAppSelector(
    (state) => state.events
  );
  const [arrayFilterCaption, setArrayFilterCaption] = React.useState<string[]>(
    []
  );


  const colorTextButton = JSON.stringify(mainFilter) != JSON.stringify(boxForFilter) ? "text-[#3361FF]" : "text-[#9295AB]";
  React.useEffect(() => {
    const filterCaption = createQueryFilter(boxForFilter);
    setArrayFilterCaption(filterCaption);
  }, [boxForFilter]);
  React.useEffect(()=>{
 
  },[boxForFilter])

  const onClickButton = (idx: number) => {
    const a = [...arrayFilterCaption];
    const item = a.splice(idx, 1)[0];
    setArrayFilterCaption(a);
    switch (true){
        case item.slice(0,19) === boxForFilter.date_gte.slice(0,19):
          dispatch(setDateFromFilter(getStartOfDay({})))
          break
        case item.slice(0,19) === boxForFilter.date_lte.slice(0,19):
          dispatch(setDateToFilter(getEndOfDay({})))
          break
        default:

          dispatch(setBoxToMainFilter(removeItemFilter(item, boxForFilter)))
    }
  };
  function isDateString(item:string) {
    // Это регулярное выражение проверяет формат YYYY-MM-DD
    const dateRegex = /^\d{4}-\d{2}-\d{2}?/;
    return dateRegex.test(item);
  }
  console.log('boxForFilter, arrayFilterCaption', boxForFilter, mainFilter)
  console.log('mainFilter == boxForFilter',mainFilter == boxForFilter)
  console.log(JSON.stringify(mainFilter) === JSON.stringify(boxForFilter))
  


  return (
    <div className="flex  justify-start gap-1 flex-wrap m-5">
      <ButtonClearFilter />
    
      <button
        disabled={JSON.stringify(mainFilter) == JSON.stringify(boxForFilter)}
        className={`flex pt-[3px] font-gilroy-bold text-[14px] justify-center items-center gap-3 w-[96px] h-[38px] bg-[#D5D9DD]/20 rounded-[10px] ${colorTextButton}`}
        onClick={() => {
          dispatch(setMainFilter(boxForFilter));
          dispatch(setFilterFrameActive(false));
          dispatch(setArchiveFrameActive("listEventsFrame"));
        }}>
            Применить
        </button>
      {arrayFilterCaption.map((item, idx) => (
        <FilterItemView
          name={isDateString(item)? new Date(new Date(item).setHours(new Date(item).getHours() - 3)).toLocaleString(): item}
          number={idx}
          onClick={() => {
            onClickButton(idx);
          }}
        />
      ))}
    </div>
  );
};

export default StateFilterView;
