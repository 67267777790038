import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import FastFilter from "../AdditionalArchiveBlock/СlockTimeLine/FastFilter";
import { FilterIcon } from "../../Burger/BurgerIcons/BurgerIcons";
import { setFilterFrameActive } from "../../../redux/slices/eventSlice";

export const AdditionalBlockHeader = () => {
  const { archiveButtonActive, isFilterOn } = useAppSelector(
    (state) => state.events
  );
  const dispatch = useAppDispatch();

  return (
    <div className="flex font-gilroy-bold w-[600px] h-[65px] items-center justify-center border-b-2 px-[15px] ">
      <div className="flex-1 items-center justify-between self-stretch  text-[24px] text-center">
        <FastFilter />
      </div>
      <div>
      <FilterIcon
        active={isFilterOn}
        onClick={() => dispatch(setFilterFrameActive(!archiveButtonActive.filterFrameActive))}
      />
      </div>    
    </div>
  );
};
