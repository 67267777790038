import { Line } from "@ant-design/plots";
import React from "react";
import ReactDOM from "react-dom";
import { useGetGropByIntervalQuery } from "../../../../services/statisticApi";
import { useAppSelector } from "../../../../hooks/hooks";
import { te } from "date-fns/locale";
import { GroupByIntervalItem } from "../../../../services/statisticApi";
const Charts = () => {
  const { timeRange, period } = useAppSelector((state) => state.statistic);

  const { data: testData } = useGetGropByIntervalQuery({
    interval: period === "hour" ? "minute" : "hour",
    params: {
      date_lte: timeRange?.date_end,
      date_gte: timeRange?.date_start,
    },
  });
  const [dataDisplayed, setDataDisplayed] = React.useState<any>([]);
  React.useEffect(() => {
    if (testData) {
      const newData = [...testData.items];
      newData.sort((a, b) => {
        if (a._id?.day && b._id?.day) {
          if (a._id.day < b._id.day) return -1;
          if (a._id.day > b._id.day) return 1;
        }
        if (a._id.hour < b._id.hour) return -1;
        if (a._id.hour > b._id.hour) return 1;

        if (a._id?.minute && b._id?.minute) {
          if (a._id.minute < b._id.minute) return -1;
          if (a._id.minute > b._id.minute) return 1;
        }
        return 0;
      });
      setDataDisplayed(newData);
    }
  }, [testData]);

  const data = dataDisplayed.map((item: GroupByIntervalItem) => ({
    year: item._id?.day
      ? `${item._id.day}:${item._id.hour}`
      : `${item._id.hour} ${item._id.minute}:`,
    value: item.count,
  }));

  const config = {
    data,
    xField: "year",
    yField: "value",
    point: {
      shapeField: "square",
      sizeField: 0,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
  };
  return <Line className="basis-9/12" {...config} />;
};

export default Charts;
