import React from "react";
import { Select, Space } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { setPeriod } from "../../redux/slices/statisticSlice";

const TimeSwitcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const period = useAppSelector((state) => state.statistic.period);
  const handleChange = (value: "day" | "shift1" | "shift2" | "hour") => {
    dispatch(setPeriod(value));
  };

  return (
    <Space wrap>
      <Select
        defaultValue="day"
        style={{ width: 120 }}
        onChange={handleChange}
        options={[
          { value: "day", label: "24 часа" },
          { value: "hour", label: "Час" },
          { value: "shift1", label: "1 cмена" },
          { value: "shift2", label: "2 cмены" },
        ]}
      />
    </Space>
  );
};

export default TimeSwitcher;
